import { useMutation } from '@apollo/client';
import { Select as AntdSelect, Button, Form, Space, Typography } from 'antd';
import React, { useEffect, useMemo } from 'react';
import {
  AUTO_GENERATED_OPTIONS,
  AUTO_GENERATED_TYPES,
  MODULE_TYPES,
  PAGE_TYPES,
  STATUS_TYPES,
  TEMPLATE_OPTION,
  getAutoGenerateByDataValue,
  getAutoGeneratedByIdProps
} from '../../../../../common/constants';
import { SelectArticle } from '../../../../labels/collections/components/FormInputs';
import { GET_COLLECTION_ITEMS } from '../../../../labels/collections/graphql/Queries';
import { Select } from '../../../../videos/components/FormInputs';
import { FORM_TYPES } from '../../../context/EditPageProvider';
import {
  CREATE_PAGE_MODULE,
  UPDATE_PAGE_MODULE
} from '../../../graphql/Mutations';
import { ModuleFields, Permissions, ShowFields, Switch } from './FormInputs';

const AUTO_GENERATED_SORT_BY_TYPES = {
  RECENT: 'RECENT',
  RANDOM: 'RANDOM',
  READS: 'READS'
};

const ARTICLE_GENERATED_TYPE = {
  CONTRIBUTOR: 'CONTRIBUTOR',
  COLLECTION: 'COLLECTION',
  TAG: 'TAG',
  TOPIC: 'TOPIC'
};
const CONFIG_TITLE = {
  [MODULE_TYPES.ARTICLE_LIST]: 'Article List',
  [MODULE_TYPES.ARTICLE_CAROUSEL]: 'Article Carousel',
  [MODULE_TYPES.FEATURED_ARTICLE]: 'Featured Article',
  [MODULE_TYPES.ARTICLE_GRID]: 'Article Grid',
  [MODULE_TYPES.FEATURED_ARTICLE_SLIDER]: 'Featured Article Slider',
  [MODULE_TYPES.ARTICLE_DISPLAY]: 'Article Display'
};

const SETTINGS = [
  {
    name: 'viewAll',
    label: 'View All Button',
    allowedTypes: [
      MODULE_TYPES.ARTICLE_CAROUSEL,
      MODULE_TYPES.ARTICLE_GRID,
      MODULE_TYPES.ARTICLE_LIST
    ]
  },
  {
    name: 'viewSearch',
    label: 'Search',
    allowedTypes: [MODULE_TYPES.ARTICLE_GRID, MODULE_TYPES.ARTICLE_LIST]
  },
  {
    name: 'sourceLogo',
    label: 'Source Logo',
    allowedTypes: [
      MODULE_TYPES.ARTICLE_CAROUSEL,
      MODULE_TYPES.ARTICLE_GRID,
      MODULE_TYPES.ARTICLE_LIST,
      MODULE_TYPES.FEATURED_ARTICLE,
      MODULE_TYPES.FEATURED_ARTICLE_SLIDER
    ]
  },
  {
    name: 'articleTopic',
    label: 'Topics',
    allowedTypes: [
      MODULE_TYPES.ARTICLE_CAROUSEL,
      MODULE_TYPES.ARTICLE_LIST,
      MODULE_TYPES.FEATURED_ARTICLE,
      MODULE_TYPES.ARTICLE_GRID,
      MODULE_TYPES.FEATURED_ARTICLE_SLIDER,
      MODULE_TYPES.ARTICLE_DISPLAY
    ]
  },
  {
    name: 'articleDescription',
    label: 'Description',
    allowedTypes: [
      MODULE_TYPES.ARTICLE_CAROUSEL,
      MODULE_TYPES.ARTICLE_LIST,
      MODULE_TYPES.FEATURED_ARTICLE,
      MODULE_TYPES.FEATURED_ARTICLE_SLIDER
    ]
  },
  {
    name: 'articleKebabMenu',
    label: 'Kebab Menu',
    allowedTypes: [
      MODULE_TYPES.ARTICLE_CAROUSEL,
      MODULE_TYPES.ARTICLE_GRID,
      MODULE_TYPES.ARTICLE_LIST
    ]
  },
  {
    name: 'articleContributor',
    label: 'Contributor',
    allowedTypes: [MODULE_TYPES.ARTICLE_DISPLAY]
  },
  {
    name: 'articleDate',
    label: 'Date',
    allowedTypes: [MODULE_TYPES.ARTICLE_DISPLAY]
  }
];
const AUTO_GENERATED_SORT_BY_TYPES_OPTIONS = [
  { label: 'Recent', value: AUTO_GENERATED_SORT_BY_TYPES.RECENT },
  { label: 'Random', value: AUTO_GENERATED_SORT_BY_TYPES.RANDOM },
  { label: 'Reads', value: AUTO_GENERATED_SORT_BY_TYPES.READS }
];

const getInitialValues = (type) => ({
  title: '',
  description: '',
  permissions: [],
  status: STATUS_TYPES.PUBLISHED,
  isDefaultModule: false,
  settings: {
    title: true,
    description: true,
    articleAuthor: true,
    articleTimestamp: true,
    articleTopic: true,
    articleDate: true,
    articleContributor: true,
    relatedContent: true,
    articleHtml: true,
    viewAll: true,
    sourceLogo: true,
    articleDescription: true,
    articleKebabMenu: true,
    viewSearch: true
  },
  config: {
    autoGenerate: false,
    autoGenerateById: '',
    autoGenerateByType: ARTICLE_GENERATED_TYPE[0],
    autoGenerateBySubCollectionId: null,
    autoGenerateSortBy: AUTO_GENERATED_SORT_BY_TYPES[0],
    articles: [
      MODULE_TYPES.FEATURED_ARTICLE,
      MODULE_TYPES.ARTICLE_DISPLAY
    ]?.includes(type)
      ? { id: '', url: '', title: '' }
      : []
  }
});

const { ARTICLE } = PAGE_TYPES;
const REST_PAGE_TYPES = [
  PAGE_TYPES.TOPIC,
  PAGE_TYPES.TAG,
  PAGE_TYPES.CONTRIBUTOR,
  PAGE_TYPES.COLLECTION
];

const ALLOWED_TEMPLATE_CONFIGS = {
  [MODULE_TYPES.ARTICLE_LIST]: [...REST_PAGE_TYPES],
  [MODULE_TYPES.ARTICLE_CAROUSEL]: [...REST_PAGE_TYPES],
  [MODULE_TYPES.FEATURED_ARTICLE]: [ARTICLE, ...REST_PAGE_TYPES],
  [MODULE_TYPES.ARTICLE_DISPLAY]: [ARTICLE, ...REST_PAGE_TYPES]
};

const MODULE_KEYS = {
  [MODULE_TYPES.ARTICLE_LIST]: 'articleListModule',
  [MODULE_TYPES.ARTICLE_CAROUSEL]: 'articleCarouselModule',
  [MODULE_TYPES.FEATURED_ARTICLE]: 'featuredArticleModule',
  [MODULE_TYPES.ARTICLE_GRID]: 'articleGridModule',
  [MODULE_TYPES.FEATURED_ARTICLE_SLIDER]: 'featuredArticleSliderModule',
  [MODULE_TYPES.ARTICLE_DISPLAY]: 'articleDisplayModule'
};

function ArticleForm({
  form: { type: formType, moduleId, defaultValues, index: order },
  pageId,
  type,
  onCancel,
  onSuccess,
  isDefaultPage,
  pageType,
  onSettingsChange
}) {
  const [form] = Form.useForm();
  const isEdit = formType === FORM_TYPES.EDIT;
  const [addEditModule, { loading }] = useMutation(
    isEdit ? UPDATE_PAGE_MODULE : CREATE_PAGE_MODULE
  );
  const collection = Form?.useWatch(['config', 'autoGenerateById'], form);
  const collectionType = Form?.useWatch(['config', 'autoGenerateByType'], form);
  const collectionId = collection?.value;
  const isMultiArticle = ![
    MODULE_TYPES.FEATURED_ARTICLE,
    MODULE_TYPES.ARTICLE_DISPLAY
  ]?.includes(type);
  const { autoGenerateByType, autoGenerate } =
    Form.useWatch(['config'], form) ?? {};
  const isDefaultModule = Form.useWatch(['isDefaultModule'], form);

  const allowedTemplateConfig =
    isDefaultPage && ALLOWED_TEMPLATE_CONFIGS[type]?.includes(pageType);
  const autoGenerateByIdProps = getAutoGeneratedByIdProps[autoGenerateByType];

  const includeOptions = useMemo(
    () => (autoGenerateByType === pageType ? [TEMPLATE_OPTION] : []),
    [autoGenerateByType, pageType]
  );
  const initialValues = useMemo(() => getInitialValues(type), [type]);

  const settingsProps = Form.useWatch(['settings'], form);

  useEffect(() => {
    if (settingsProps) {
      onSettingsChange?.(settingsProps);
    }
  }, [settingsProps]);

  useEffect(() => {
    if (moduleId && defaultValues && formType === FORM_TYPES.EDIT && form) {
      const idm = defaultValues?.isDefaultModule;
      const values = {
        title: defaultValues?.title ?? '',
        description: defaultValues?.description ?? '',
        status: defaultValues?.status ?? STATUS_TYPES.DRAFT,
        isDefaultModule: idm,
        permissions:
          defaultValues?.permissions?.map((value) => ({
            label: value,
            value
          })) ?? [],
        settings: {
          ...initialValues.settings,
          ...defaultValues?.moduleData?.settings
        },
        config: {
          autoGenerate:
            defaultValues?.moduleData?.config?.autoGenerate ?? false,
          autoGenerateByType:
            defaultValues?.moduleData?.config?.autoGenerateByType || null,
          autoGenerateById: idm
            ? TEMPLATE_OPTION
            : getAutoGenerateByDataValue[
                defaultValues?.moduleData?.config?.autoGenerateByType
              ]?.(defaultValues?.moduleData?.config?.autoGenerateByData) ??
              null,
          autoGenerateBySubCollectionId:
            defaultValues?.moduleData?.config
              ?.autoGenerateBySubCollectionData === null
              ? { label: 'All', value: 'all' }
              : {
                  label:
                    defaultValues?.moduleData?.config
                      ?.autoGenerateBySubCollectionData?.title,
                  value:
                    defaultValues?.moduleData?.config
                      ?.autoGenerateBySubCollectionData?.id
                },
          autoGenerateSortBy:
            defaultValues?.moduleData?.config?.autoGenerateSortBy || null,
          articles: [
            MODULE_TYPES.FEATURED_ARTICLE,
            MODULE_TYPES.ARTICLE_DISPLAY
          ]?.includes(type)
            ? {
                id: defaultValues?.moduleData?.config?.article?.id,
                title: defaultValues?.moduleData?.config?.article?.title,
                url:
                  defaultValues?.moduleData?.config?.article?.imageThumbnail
                    ?.url
              }
            : defaultValues?.moduleData?.config?.articles?.map(
                ({ id, title, imageThumbnail }) => ({
                  id,
                  title,
                  url: imageThumbnail?.url ?? ''
                })
              )
        }
      };
      form.setFieldsValue(values);
    }
  }, [form, moduleId, formType, defaultValues, form, initialValues, type]);

  const handleSubmit = (values) => {
    // eslint-disable-next-line no-shadow
    const { config, settings, permissions, isDefaultModule, ...rest } =
      values ?? {};
    const key = MODULE_KEYS[type];

    const {
      articles,
      autoGenerate: articleAutoGenerate,
      autoGenerateById = '',
      autoGenerateBySubCollectionId,
      ...restConfig
    } = config ?? {};

    const isDefault = allowedTemplateConfig && isDefaultModule;

    if (!key) return;
    const payload = {
      ...(!isEdit && {
        type,
        order: order + 1
      }),
      permissions: permissions?.map(({ value }) => value),
      ...rest,
      [key]: {
        settings,
        config: {
          ...restConfig,
          ...(isDefault
            ? {
                articleId: '*'
              }
            : {
                ...([
                  MODULE_TYPES.FEATURED_ARTICLE,
                  MODULE_TYPES.ARTICLE_DISPLAY
                ]?.includes(type)
                  ? {
                      articleId: articles?.id
                    }
                  : {
                      articles:
                        articles?.map(({ id }, i) => ({
                          articleId: id,
                          order: i + 1
                        })) ?? []
                    })
              }),
          autoGenerate: articleAutoGenerate,
          ...(articleAutoGenerate &&
            autoGenerateBySubCollectionId && {
              autoGenerateBySubCollectionId:
                autoGenerateBySubCollectionId?.value === 'all'
                  ? null
                  : autoGenerateBySubCollectionId?.value
            }),
          autoGenerateById: isEdit
            ? autoGenerateById?.value
            : autoGenerateById?.key ?? null
        }
      }
    };

    addEditModule({
      variables: { data: payload, id: isEdit ? moduleId : pageId }
    }).then(
      ({
        data: {
          addUpdatedPageModule: { pageModule }
        }
      }) => {
        onSuccess(pageModule);
      }
    );
  };

  return (
    <>
      <Form
        layout="vertical"
        form={form}
        onFinish={handleSubmit}
        initialValues={initialValues}
      >
        <ModuleFields
          title={{
            settingToggle: [MODULE_TYPES.ARTICLE_CAROUSEL].includes(type)
          }}
          description={{
            settingToggle: [MODULE_TYPES.ARTICLE_CAROUSEL].includes(type)
          }}
        />
        <Space className="w-full" direction="vertical">
          <Space className="w-full" direction="vertical">
            <ShowFields settings={SETTINGS} type={type} />
            {allowedTemplateConfig && pageType === ARTICLE && (
              <>
                <Typography.Text>Template Config</Typography.Text>
                <Form.Item
                  className="m-0"
                  name="isDefaultModule"
                  valuePropName="checked"
                >
                  <Switch label="Use Template Data" />
                </Form.Item>
              </>
            )}
            {(!allowedTemplateConfig ||
              (allowedTemplateConfig && !isDefaultModule)) && (
              <>
                <Typography.Text>{CONFIG_TITLE[type]}</Typography.Text>
                <Form.Item
                  className="m-0"
                  name={['config', 'autoGenerate']}
                  valuePropName="checked"
                >
                  <Switch label="Auto Generate" />
                </Form.Item>
                {autoGenerate && (
                  <>
                    <Form.Item
                      label="Auto Generated By Type"
                      name={['config', 'autoGenerateByType']}
                    >
                      <AntdSelect
                        options={AUTO_GENERATED_OPTIONS}
                        placeholder="Select type"
                        onChange={() => {
                          form.setFieldValue(
                            ['config', 'autoGenerateById'],
                            null
                          );
                        }}
                      />
                    </Form.Item>
                    {autoGenerateByType && autoGenerateByIdProps && (
                      <Form.Item
                        label="Auto Generated By"
                        name={['config', 'autoGenerateById']}
                      >
                        <Select
                          placeholder="Select"
                          query={autoGenerateByIdProps?.query}
                          variablesSelector={({ skip, limit, search }) => ({
                            filter: {
                              skip,
                              limit,
                              search
                            },
                            ...(autoGenerateByType ===
                              AUTO_GENERATED_TYPES.CONTRIBUTOR && {
                              where: { isActive: true }
                            })
                          })}
                          dataSelector={autoGenerateByIdProps?.dataSelector}
                          keys={autoGenerateByIdProps?.keys}
                          {...(allowedTemplateConfig && {
                            includeOptions
                          })}
                          onChange={() => {
                            form.setFieldValue(
                              ['config', 'autoGenerateBySubCollectionId'],
                              null
                            );
                          }}
                        />
                      </Form.Item>
                    )}
                    {collectionType === AUTO_GENERATED_TYPES?.COLLECTION &&
                      collectionId && (
                        <Form.Item
                          label="Select SubCollection"
                          name={['config', 'autoGenerateBySubCollectionId']}
                        >
                          <Select
                            placeholder="Select sub collection"
                            query={GET_COLLECTION_ITEMS}
                            variablesSelector={() => ({
                              where: {
                                id: collectionId
                              }
                            })}
                            dataSelector={(data) =>
                              data?.collectionItemsAdmin?.collectionItems?.map(
                                (item) => ({
                                  label: item?.itemData?.title,
                                  value: item?.itemData?.id
                                })
                              ) ?? []
                            }
                            includeOptions={[{ label: 'All', value: 'all' }]}
                            keys={{
                              data: 'collectionItemsAdmin',
                              records: 'collectionItems',
                              count: 'count'
                            }}
                          />
                        </Form.Item>
                      )}
                    <Form.Item
                      label="Auto Generated Sort By"
                      name={['config', 'autoGenerateSortBy']}
                    >
                      <AntdSelect
                        options={AUTO_GENERATED_SORT_BY_TYPES_OPTIONS}
                        placeholder="Select sort by"
                      />
                    </Form.Item>
                  </>
                )}
                {!autoGenerate && (
                  <Form.Item
                    label={
                      isMultiArticle ? 'Select Articles' : 'Select Article'
                    }
                    name={['config', 'articles']}
                    extra={
                      isMultiArticle ? 'Select items in order you want' : ''
                    }
                  >
                    <SelectArticle multiple={isMultiArticle} />
                  </Form.Item>
                )}
              </>
            )}
            <Permissions />
            <div className="d-flex button-section">
              <Space>
                <Form.Item>
                  <Button
                    type="text"
                    htmlType="submit"
                    className="text-btn mr-8"
                    size="middle"
                    disabled={loading}
                    loading={loading}
                  >
                    Save
                  </Button>
                </Form.Item>

                <Form.Item>
                  <Button
                    disabled={loading}
                    onClick={onCancel}
                    type="text"
                    className="text-btn2"
                  >
                    Cancel
                  </Button>
                </Form.Item>
              </Space>
            </div>
          </Space>
        </Space>
      </Form>
    </>
  );
}

export default ArticleForm;
