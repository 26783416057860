import { useQuery } from '@apollo/client';
import React, { createContext, useCallback, useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useApp } from '../../../AppContext';
import { GET_WORKSPACE_APP } from '../graphql/Queries';

const ctx = createContext();
export const PreviewPageContext = ctx;

export const usePagePreview = () => useContext(ctx);

const PreviewPageProvider = ({ pageId, children }) => {
  const [loading, setLoading] = useState(true);
  const [loadingPlaceholderPreview, setLoadingPlaceholderPreview] = useState(
    true
  );
  const { id: pageParamId } = useParams();
  const { state } = useApp();
  const authToken = state?.authToken;
  const id = pageId || pageParamId;

  const { data, loading: loadingWorkspaceApp } = useQuery(GET_WORKSPACE_APP, {
    fetchPolicy: 'network-only',
    variables: {
      where: {
        id: state?.app?.id
      }
    },
    onCompleted(response) {
      if (!response?.workspaceApp?.url && !process.env.REACT_APP_WEB_URL) {
        setLoading(false);
        setLoadingPlaceholderPreview(false);
      }
    }
  });

  const isWebApp = data?.workspaceApp?.typeKey === 'WEB_APP';

  const webUrl =
    data && isWebApp
      ? data?.workspaceApp?.url || process.env.REACT_APP_WEB_URL || ''
      : process.env.REACT_APP_WEB_URL ?? '';

  const appUUID = state?.app?.value;
  const workspaceUUID = state?.workspace?.value;
  const appId = state?.app?.id;
  const workspaceId = state?.workspace?.id;

  const defaultUrl =
    id &&
    authToken &&
    webUrl &&
    appUUID &&
    workspaceUUID &&
    appId &&
    workspaceId &&
    !loadingWorkspaceApp
      ? `${webUrl}`
      : null;

  const getUrl = (slug) => {
    const url = webUrl
      ? `${defaultUrl}/${slug}?pageId=${id}&token=${authToken}&appUUID=${appUUID}&workspaceUUID=${workspaceUUID}&appId=${appId}&workspaceId=${workspaceId}`
      : null;
    return url;
  };
  const onLoad = useCallback(() => {
    if (getUrl('preview')) setLoading(false);
  }, [getUrl('preview')]);

  const onPlaceholderPreviewLoad = useCallback(() => {
    if (getUrl('placeholder-preview')) setLoadingPlaceholderPreview(false);
  }, [getUrl('placeholder-preview')]);

  return (
    <ctx.Provider
      value={{
        onLoad,
        loading,
        url: getUrl('preview'),
        webUrl,
        placeholderPreviewUrl: getUrl('placeholder-preview'),
        loadingPlaceholderPreview,
        onPlaceholderPreviewLoad
      }}
    >
      {children}
    </ctx.Provider>
  );
};

export default PreviewPageProvider;
