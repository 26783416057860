import { EditOutlined, EyeFilled, EyeOutlined } from '@ant-design/icons';
import { Button, Select, Tooltip } from 'antd';
import React, { useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  LIST_TYPES,
  MODULES,
  PROVIDER_TYPES,
  ROUTES,
  SORT_FIELD,
  TEMPLATE_TYPE_OPTIONS,
  WORKSPACE_ROLE_LEVEL,
  WORKSPACE_ROLE_PERMISSION
} from '../../common/constants';
import FilterBar, { useFilterBar } from '../../components/FilterBar';
import PageHeader from '../../components/PageHeader';
import PageList from '../../components/PageList';
import useCheckPermission from '../../hooks/useCheckPermission';
import { GET_NOTIFICATIONS } from './graphql/Queries';
import TemplatePreviewModal from './TemplatePreviewModal';

const dataSelector = (data) => ({
  data: data?.notificationTemplatesAdmin?.notificationTemplates ?? [],
  count: data?.notificationTemplatesAdmin?.count ?? 0
});

const variablesSelector = ({
  limit,
  offset,
  search,
  sortField,
  sortOrder,
  type
}) => ({
  filter: {
    skip: offset,
    search,
    limit,
    type
  },
  sort: {
    sortOn: sortField,
    sortBy: sortOrder
  }
});

const Templates = () => {
  const [type, setType] = useState(PROVIDER_TYPES.EMAIL);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [templateData, setTemplateData] = useState();

  const filterProps = useFilterBar({
    defaultSortOptions: {
      options: SORT_FIELD
    }
  });

  const filters = useMemo(
    () => ({
      ...filterProps?.filters,
      type
    }),
    [filterProps?.filters, type]
  );

  const handleTypeChange = (value) => {
    setType(value);
  };

  const Action = ({ id }) => {
    const history = useHistory();
    const isViewOnly = useCheckPermission([
      {
        moduleKey: WORKSPACE_ROLE_PERMISSION.NOTIFICATION_TEMPLATE_MANAGEMENT,
        allowedPermissions: [WORKSPACE_ROLE_LEVEL.VIEW]
      }
    ]);

    const handleEdit = () => {
      history.push(`${ROUTES?.TEMPLATES}/${id}/edit`);
    };

    return (
      <div className="d-flex align-center">
        <Tooltip title={`${isViewOnly ? 'View' : 'Edit'} Template`}>
          <Button type="text" className="text-btn" onClick={handleEdit}>
            {isViewOnly ? <EyeFilled /> : <EditOutlined />}
          </Button>
        </Tooltip>
      </div>
    );
  };

  const PreviewAction = ({ record }) => {
    const handlePreview = () => {
      setTemplateData(record);
      setIsModalVisible(true);
    };

    return (
      <div className="d-flex align-center">
        <Tooltip title="View Template">
          <Button type="text" className="text-btn" onClick={handlePreview}>
            <EyeOutlined />
          </Button>
        </Tooltip>
      </div>
    );
  };

  const columns = () => {
    return [
      {
        title: 'Id',
        dataIndex: 'id',
        ellipsis: true,
        width: '5%'
      },
      {
        title: 'Title',
        dataIndex: 'title',
        width: '18%'
      },
      {
        title: 'Type',
        dataIndex: 'type',
        width: '18%',
        align: 'center',
        render: (value) =>
          TEMPLATE_TYPE_OPTIONS?.find(
            (notificationType) => notificationType?.value === value
          )?.label ?? ''
      },
      {
        title: 'Default Language',
        dataIndex: 'languageCode',
        width: '18%',
        align: 'center',
        render: (value) => value ?? '-'
      },
      {
        title: 'Default',
        dataIndex: 'isDefault',
        width: '18%',
        render: (value) => (value ? 'Yes' : 'No')
      },
      {
        title: 'Preview',
        dataIndex: 'preview',
        width: '12%',
        render: (_, record) => <PreviewAction record={record} />
      },
      {
        title: 'Action',
        dataIndex: 'id',
        render: (value) => <Action id={value} />
      }
    ];
  };

  const notificationBreadcrumbs = [{ label: MODULES?.TEMPLATES }];

  return (
    <>
      <TemplatePreviewModal
        openModal={isModalVisible}
        setOpenModal={setIsModalVisible}
        templateData={templateData}
      />
      <PageHeader menu={notificationBreadcrumbs} />
      <FilterBar
        {...filterProps}
        SearchProps={{ placeholder: 'Search Template' }}
        show={{ listModes: false }}
      >
        <Select
          allowClear
          value={type}
          popupMatchSelectWidth={false}
          placeholder="Select type"
          options={TEMPLATE_TYPE_OPTIONS}
          onChange={handleTypeChange}
        />
      </FilterBar>
      <PageList
        filters={filters}
        listMode={LIST_TYPES.TABLE}
        query={GET_NOTIFICATIONS}
        variablesSelector={variablesSelector}
        dataSelector={dataSelector}
        TableProps={{ columns }}
        limit={20}
      />
    </>
  );
};

export default Templates;
