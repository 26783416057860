import { useMutation } from '@apollo/client';
import {
  Select as AntdSelect,
  Button,
  Form,
  Input,
  Space,
  Typography
} from 'antd';

import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import React, { useEffect, useMemo } from 'react';
import {
  AUTO_GENERATED_OPTIONS,
  AUTO_GENERATED_SORT_BY_OPTIONS,
  AUTO_GENERATED_TYPES,
  MODULE_TYPES,
  PAGE_TYPES,
  STATIC_DATA_KEYS,
  STATUS_TYPES,
  TEMPLATE_OPTION,
  getAutoGenerateByDataValue,
  getAutoGeneratedByIdProps
} from '../../../../../common/constants';
import { formValidatorRules } from '../../../../../common/utils';
import useStaticData from '../../../../../hooks/useStaticData';
import { SelectVideos } from '../../../../labels/collections/components/FormInputs';
import { GET_COLLECTION_ITEMS } from '../../../../labels/collections/graphql/Queries';
import { Select } from '../../../../videos/components/FormInputs';
import { FORM_TYPES } from '../../../context/EditPageProvider';
import {
  CREATE_PAGE_MODULE,
  UPDATE_PAGE_MODULE
} from '../../../graphql/Mutations';
import { ModuleFields, Permissions, ShowFields, Switch } from './FormInputs';

dayjs?.extend(duration);
dayjs.extend(utc);
dayjs.extend(timezone);

const getInitialValues = (type) => ({
  title: '',
  description: '',
  status: STATUS_TYPES.PUBLISHED,
  permissions: [],
  isDefaultModule: false,
  settings: {
    title: true,
    description: true,
    viewAll: true,
    videoTimestamp: true,
    videoDate: true,
    videoSpeaker: true,
    videoTopic: true,
    videoOrder: true,
    videoProgressBar: true,
    viewSearch: true,
    viewFilter: true,
    videoDescription: true,
    donationAction: true,
    shareAction: true,
    saveAction: true,
    transcriptAction: true,
    videoCTA: true,
    videoSave: true,
    videoKebabMenu: true
  },
  config: {
    autoGenerate: false,
    autoGenerateByType: null,
    autoGenerateById: null,
    autoGenerateBySubCollectionId: null,
    autoGenerateSortBy: null,
    autoGenerateLimit: null,
    videos: [MODULE_TYPES.FEATURED_VIDEO, MODULE_TYPES.VIDEO_PLAYER].includes(
      type
    )
      ? { id: '', url: '', title: '' }
      : []
  }
});

const SETTINGS = [
  {
    name: 'viewAll',
    label: 'View All Button',
    allowedTypes: [
      MODULE_TYPES.VIDEO_CAROUSEL,
      MODULE_TYPES.VIDEO_LIST,
      MODULE_TYPES.VIDEO_GRID
    ]
  },
  {
    name: 'videoDescription',
    label: 'Video Description',
    allowedTypes: [
      MODULE_TYPES.VIDEO_PLAYER,
      MODULE_TYPES.FEATURED_VIDEO,
      MODULE_TYPES.FEATURED_VIDEO_SLIDER,
      MODULE_TYPES.VIDEO_LIST,
      MODULE_TYPES.VIDEO_GRID
    ]
  },
  {
    name: 'viewSearch',
    label: 'View Search',
    allowedTypes: [MODULE_TYPES.VIDEO_LIST, MODULE_TYPES.VIDEO_GRID]
  },
  {
    name: 'viewFilter',
    label: 'View Filters',
    allowedTypes: [MODULE_TYPES.VIDEO_LIST, MODULE_TYPES.VIDEO_GRID]
  },
  {
    name: 'videoTimestamp',
    label: 'Timestamp',
    allowedTypes: [
      MODULE_TYPES.VIDEO_CONTINUE_WATCHING_CAROUSEL,
      MODULE_TYPES.VIDEO_WATCH_LATER_CAROUSEL,
      MODULE_TYPES.VIDEO_CAROUSEL,
      MODULE_TYPES.VIDEO_LIST,
      MODULE_TYPES.VIDEO_GRID,
      MODULE_TYPES.FEATURED_VIDEO,
      MODULE_TYPES.FEATURED_VIDEO_SLIDER
    ]
  },
  {
    name: 'videoDate',
    label: 'Date',
    allowedTypes: [
      MODULE_TYPES.VIDEO_PLAYER,
      MODULE_TYPES.FEATURED_VIDEO,
      MODULE_TYPES.FEATURED_VIDEO_SLIDER,
      MODULE_TYPES.VIDEO_CONTINUE_WATCHING_CAROUSEL,
      MODULE_TYPES.VIDEO_WATCH_LATER_CAROUSEL,
      MODULE_TYPES.VIDEO_CAROUSEL,
      MODULE_TYPES.VIDEO_LIST,
      MODULE_TYPES.VIDEO_GRID
    ]
  },
  {
    name: 'videoSpeaker',
    label: 'Speaker',
    allowedTypes: [
      MODULE_TYPES.VIDEO_PLAYER,
      MODULE_TYPES.FEATURED_VIDEO,
      MODULE_TYPES.FEATURED_VIDEO_SLIDER,
      MODULE_TYPES.VIDEO_CONTINUE_WATCHING_CAROUSEL,
      MODULE_TYPES.VIDEO_WATCH_LATER_CAROUSEL,
      MODULE_TYPES.VIDEO_CAROUSEL,
      MODULE_TYPES.VIDEO_LIST,
      MODULE_TYPES.VIDEO_GRID
    ]
  },
  {
    name: 'videoTopic',
    label: 'Topic',
    allowedTypes: [
      MODULE_TYPES.VIDEO_PLAYER,
      MODULE_TYPES.FEATURED_VIDEO,
      MODULE_TYPES.FEATURED_VIDEO_SLIDER,
      MODULE_TYPES.VIDEO_CONTINUE_WATCHING_CAROUSEL,
      MODULE_TYPES.VIDEO_WATCH_LATER_CAROUSEL,
      MODULE_TYPES.VIDEO_CAROUSEL,
      MODULE_TYPES.VIDEO_LIST,
      MODULE_TYPES.VIDEO_GRID
    ]
  },
  {
    name: 'videoOrder',
    label: 'Order as Selected',
    allowedTypes: [
      MODULE_TYPES.VIDEO_PLAYER,
      MODULE_TYPES.VIDEO_CAROUSEL,
      MODULE_TYPES.VIDEO_LIST,
      MODULE_TYPES.VIDEO_GRID,
      MODULE_TYPES.FEATURED_VIDEO_SLIDER
    ]
  },
  {
    name: 'videoProgressBar',
    label: 'Progress Bar',
    allowedTypes: [
      MODULE_TYPES.VIDEO_CAROUSEL,
      MODULE_TYPES.VIDEO_LIST,
      MODULE_TYPES.VIDEO_GRID
    ]
  },
  {
    name: 'videoKebabMenu',
    label: 'Kebab Menu',
    allowedTypes: [
      MODULE_TYPES.VIDEO_CAROUSEL,
      MODULE_TYPES.VIDEO_LIST,
      MODULE_TYPES.VIDEO_GRID
    ]
  },
  {
    name: 'donationAction',
    label: 'Donation Action',
    allowedTypes: [MODULE_TYPES.VIDEO_PLAYER]
  },
  {
    name: 'shareAction',
    label: 'Share Action',
    allowedTypes: [MODULE_TYPES.VIDEO_PLAYER]
  },
  {
    name: 'saveAction',
    label: 'Save Action',
    allowedTypes: [MODULE_TYPES.VIDEO_PLAYER]
  },
  {
    name: 'transcriptAction',
    label: 'Transcript Action',
    allowedTypes: [MODULE_TYPES.VIDEO_PLAYER]
  },
  {
    name: 'videoCTA',
    label: 'Play Action',
    allowedTypes: [
      MODULE_TYPES.FEATURED_VIDEO,
      MODULE_TYPES.FEATURED_VIDEO_SLIDER
    ]
  },
  {
    name: 'videoSave',
    label: 'Save Action',
    allowedTypes: [
      MODULE_TYPES.FEATURED_VIDEO,
      MODULE_TYPES.FEATURED_VIDEO_SLIDER
    ]
  }
];

const MODULE_KEYS = {
  [MODULE_TYPES.VIDEO_CONTINUE_WATCHING_CAROUSEL]:
    'videoContinueWatchingCarouselModule',
  [MODULE_TYPES.VIDEO_WATCH_LATER_CAROUSEL]: 'videoWatchLaterCarouselModule',
  [MODULE_TYPES.VIDEO_CAROUSEL]: 'videoCarouselModule',
  [MODULE_TYPES.VIDEO_LIST]: 'videoListModule',
  [MODULE_TYPES.VIDEO_GRID]: 'videoGridModule',
  [MODULE_TYPES.FEATURED_VIDEO]: 'featuredVideoModule',
  [MODULE_TYPES.FEATURED_VIDEO_SLIDER]: 'featuredVideoSliderModule',
  [MODULE_TYPES.VIDEO_PLAYER]: 'videoPlayerModule'
};

const CONFIG_TITLE = {
  [MODULE_TYPES.VIDEO_CONTINUE_WATCHING_CAROUSEL]:
    'Video Continue Watching Configs',
  [MODULE_TYPES.VIDEO_WATCH_LATER_CAROUSEL]: 'Video Watch Later Configs',
  [MODULE_TYPES.VIDEO_CAROUSEL]: 'Video Carousel Configs',
  [MODULE_TYPES.VIDEO_LIST]: 'Video List Configs',
  [MODULE_TYPES.VIDEO_GRID]: 'Video Grid Configs',
  [MODULE_TYPES.FEATURED_VIDEO]: 'Feature Video Configs',
  [MODULE_TYPES.FEATURED_VIDEO_SLIDER]: 'Feature Video Slider Configs',
  [MODULE_TYPES.VIDEO_PLAYER]: 'Video Player Configs'
};

const { VIDEO } = PAGE_TYPES;
const REST_PAGE_TYPES = [
  PAGE_TYPES.TOPIC,
  PAGE_TYPES.TAG,
  PAGE_TYPES.CONTRIBUTOR,
  PAGE_TYPES.COLLECTION
];

const ALLOWED_TEMPLATE_CONFIGS = {
  [MODULE_TYPES.VIDEO_CAROUSEL]: [...REST_PAGE_TYPES],
  [MODULE_TYPES.VIDEO_LIST]: [...REST_PAGE_TYPES],
  [MODULE_TYPES.VIDEO_GRID]: [...REST_PAGE_TYPES],
  [MODULE_TYPES.FEATURED_VIDEO]: [VIDEO, ...REST_PAGE_TYPES],
  [MODULE_TYPES.FEATURED_VIDEO_SLIDER]: [...REST_PAGE_TYPES],
  [MODULE_TYPES.VIDEO_PLAYER]: [VIDEO]
};

const VideoForm = ({
  form: { type: formType, moduleId, defaultValues, index: order },
  pageId,
  type,
  onCancel,
  onSuccess,
  isDefaultPage,
  pageType,
  onSettingsChange
}) => {
  const [form] = Form.useForm();
  const isEdit = formType === FORM_TYPES.EDIT;
  const collection = Form?.useWatch(['config', 'autoGenerateById'], form);
  const collectionType = Form?.useWatch(['config', 'autoGenerateByType'], form);
  const collectionId = collection?.value;
  const initialValues = useMemo(() => getInitialValues(type), [type]);
  const allowedTemplateConfig =
    isDefaultPage && ALLOWED_TEMPLATE_CONFIGS[type]?.includes(pageType);
  const isMultiVideo = ![
    MODULE_TYPES.FEATURED_VIDEO,
    MODULE_TYPES.VIDEO_PLAYER
  ].includes(type);

  const { data: configData } = useStaticData(STATIC_DATA_KEYS.CONFIGS);

  const [addEditModule, { loading }] = useMutation(
    isEdit ? UPDATE_PAGE_MODULE : CREATE_PAGE_MODULE
  );

  const configProps = Form.useWatch(['config'], form);
  const isDefaultModule = Form.useWatch(['isDefaultModule'], form);
  const { autoGenerateByType, autoGenerate } =
    configProps ?? initialValues.config;

  const includeOptions = useMemo(
    () => (autoGenerateByType === pageType ? [TEMPLATE_OPTION] : []),
    [autoGenerateByType, pageType]
  );

  const settingsProps = Form.useWatch(['settings'], form);
  useEffect(() => {
    if (settingsProps) {
      onSettingsChange?.(settingsProps);
    }
  }, [settingsProps]);

  useEffect(() => {
    if (moduleId && defaultValues && formType === FORM_TYPES.EDIT && form) {
      const idm = defaultValues?.isDefaultModule;

      form.setFieldsValue({
        title: defaultValues?.title ?? '',
        description: defaultValues?.description ?? '',
        status: defaultValues?.status ?? STATUS_TYPES.DRAFT,
        isDefaultModule: idm,
        permissions:
          defaultValues?.permissions?.map((value) => ({
            label: value,
            value
          })) ?? [],
        settings: {
          ...initialValues.settings,
          ...defaultValues?.moduleData?.settings
        },
        ...(![
          MODULE_TYPES.VIDEO_CONTINUE_WATCHING_CAROUSEL,
          MODULE_TYPES.VIDEO_WATCH_LATER_CAROUSEL
        ].includes(type) && {
          config: {
            ...(![MODULE_TYPES.VIDEO_PLAYER]?.includes(type) && {
              autoGenerate:
                defaultValues?.moduleData?.config?.autoGenerate ?? false,
              autoGenerateByType:
                defaultValues?.moduleData?.config?.autoGenerateByType || null,
              autoGenerateById: idm
                ? TEMPLATE_OPTION
                : getAutoGenerateByDataValue[
                    defaultValues?.moduleData?.config?.autoGenerateByType
                  ]?.(defaultValues?.moduleData?.config?.autoGenerateByData) ??
                  null,
              autoGenerateBySubCollectionId:
                defaultValues?.moduleData?.config
                  ?.autoGenerateBySubCollectionData === null
                  ? { label: 'All', value: 'all' }
                  : {
                      label:
                        defaultValues?.moduleData?.config
                          ?.autoGenerateBySubCollectionData?.title,
                      value:
                        defaultValues?.moduleData?.config
                          ?.autoGenerateBySubCollectionData?.id
                    },
              autoGenerateSortBy:
                defaultValues?.moduleData?.config?.videoAutoGenerateSortBy ||
                null,
              autoGenerateLimit:
                defaultValues?.moduleData?.config?.autoGenerateLimit || null
            }),
            videos: isMultiVideo
              ? defaultValues?.moduleData?.config?.videos?.map(
                  ({ id, title, imageThumbnail, isLive }) => ({
                    id,
                    title,
                    url: imageThumbnail?.url ?? '',
                    isLive
                  })
                ) || []
              : {
                  id: defaultValues?.moduleData?.config?.video?.id ?? '',
                  title: defaultValues?.moduleData?.config?.video?.title ?? '',
                  url:
                    defaultValues?.moduleData?.config?.video?.imageThumbnail
                      ?.url ?? '',
                  isLive: defaultValues?.moduleData?.config?.video?.isLive
                }
          }
        })
      });
    }
  }, [
    form,
    moduleId,
    formType,
    defaultValues,
    form,
    initialValues,
    isMultiVideo
  ]);

  const handleSubmit = ({
    config,
    settings,
    permissions,
    // eslint-disable-next-line no-shadow
    isDefaultModule,
    ...rest
  }) => {
    const key = MODULE_KEYS[type];
    if (!key) return;

    const isDefault = allowedTemplateConfig && isDefaultModule;

    const {
      videos,
      autoGenerate: autoGenerateVideoConfig,
      autoGenerateById,
      autoGenerateLimit,
      autoGenerateBySubCollectionId,
      ...restConfig
    } = config ?? {};

    const payload = {
      ...(!isEdit && {
        type,
        order: order + 1
      }),
      permissions: permissions?.map(({ value }) => value),
      ...rest,
      [key]: {
        settings,
        ...(![
          MODULE_TYPES.VIDEO_CONTINUE_WATCHING_CAROUSEL,
          MODULE_TYPES.VIDEO_WATCH_LATER_CAROUSEL
        ].includes(type) && {
          config: {
            autoGenerate: autoGenerateVideoConfig,
            autoGenerateById: autoGenerateById?.value,
            ...(autoGenerateVideoConfig &&
              autoGenerateBySubCollectionId && {
                autoGenerateBySubCollectionId:
                  autoGenerateBySubCollectionId?.value === 'all'
                    ? null
                    : autoGenerateBySubCollectionId?.value
              }),
            ...restConfig,
            ...(isDefault
              ? {
                  videoId: '*'
                }
              : {
                  ...(!autoGenerateVideoConfig &&
                    isMultiVideo && {
                      videos:
                        videos?.map(({ id }, i) => ({
                          videoId: id,
                          order: i + 1
                        })) ?? []
                    }),
                  ...(!autoGenerateVideoConfig &&
                    !isMultiVideo && {
                      videoId: videos?.id ?? ''
                    })
                }),
            ...(autoGenerateLimit && {
              autoGenerateLimit: Number(autoGenerateLimit)
            })
          }
        })
      }
    };

    addEditModule({
      variables: { data: payload, id: isEdit ? moduleId : pageId }
    }).then(
      ({
        data: {
          addUpdatedPageModule: { pageModule }
        }
      }) => {
        onSuccess(pageModule);
      }
    );
  };

  const autoGenerateByIdProps = getAutoGeneratedByIdProps[autoGenerateByType];

  return (
    <Form
      layout="vertical"
      form={form}
      onFinish={handleSubmit}
      initialValues={initialValues}
    >
      <ModuleFields
        title={{
          settingToggle: [
            MODULE_TYPES.VIDEO_CAROUSEL,
            MODULE_TYPES.VIDEO_LIST,
            MODULE_TYPES.VIDEO_PLAYER,
            MODULE_TYPES.VIDEO_GRID,
            MODULE_TYPES.FEATURED_VIDEO,
            MODULE_TYPES.FEATURED_VIDEO_SLIDER,
            MODULE_TYPES.VIDEO_CONTINUE_WATCHING_CAROUSEL,
            MODULE_TYPES.VIDEO_WATCH_LATER_CAROUSEL
          ].includes(type)
        }}
        description={{
          settingToggle: [
            MODULE_TYPES.VIDEO_CAROUSEL,
            MODULE_TYPES.VIDEO_LIST,
            MODULE_TYPES.VIDEO_PLAYER,
            MODULE_TYPES.VIDEO_GRID,
            MODULE_TYPES.FEATURED_VIDEO,
            MODULE_TYPES.FEATURED_VIDEO_SLIDER,
            MODULE_TYPES.VIDEO_CONTINUE_WATCHING_CAROUSEL,
            MODULE_TYPES.VIDEO_WATCH_LATER_CAROUSEL
          ].includes(type)
        }}
      />
      <Space className="w-full" direction="vertical">
        <ShowFields settings={SETTINGS} type={type} />
        {![
          MODULE_TYPES.VIDEO_CONTINUE_WATCHING_CAROUSEL,
          MODULE_TYPES.VIDEO_WATCH_LATER_CAROUSEL
        ].includes(type) && (
          <Space className="w-full" direction="vertical">
            {allowedTemplateConfig && pageType === VIDEO && (
              <>
                <Typography.Text>Template Config</Typography.Text>
                <Form.Item
                  className="m-0"
                  name="isDefaultModule"
                  valuePropName="checked"
                >
                  <Switch
                    reverse
                    flexProps={{ justify: 'space-between' }}
                    label="Use Template Data"
                  />
                </Form.Item>
              </>
            )}

            {(!allowedTemplateConfig ||
              (allowedTemplateConfig && !isDefaultModule)) && (
              <>
                <Typography.Text>{CONFIG_TITLE[type]}</Typography.Text>
                <div>
                  {![MODULE_TYPES.VIDEO_PLAYER]?.includes(type) && (
                    <>
                      <Form.Item
                        name={['config', 'autoGenerate']}
                        valuePropName="checked"
                      >
                        <Switch
                          reverse
                          flexProps={{ justify: 'space-between' }}
                          label={
                            isMultiVideo
                              ? 'Auto Generated Videos'
                              : 'Auto Generated Video'
                          }
                        />
                      </Form.Item>
                      {autoGenerate && (
                        <>
                          <Form.Item
                            label="Auto Generated By Type"
                            name={['config', 'autoGenerateByType']}
                          >
                            <AntdSelect
                              options={AUTO_GENERATED_OPTIONS}
                              placeholder="Select type"
                              onChange={() => {
                                form.setFieldValue(
                                  ['config', 'autoGenerateById'],
                                  null
                                );
                              }}
                            />
                          </Form.Item>
                          {autoGenerateByType && autoGenerateByIdProps && (
                            <Form.Item
                              label="Auto Generated By"
                              name={['config', 'autoGenerateById']}
                            >
                              <Select
                                placeholder="Select"
                                query={autoGenerateByIdProps?.query}
                                variablesSelector={(filter) => ({
                                  filter,
                                  ...(autoGenerateByType ===
                                    AUTO_GENERATED_TYPES.CONTRIBUTOR && {
                                    where: { isActive: true }
                                  })
                                })}
                                dataSelector={
                                  autoGenerateByIdProps?.dataSelector
                                }
                                keys={autoGenerateByIdProps?.keys}
                                {...(allowedTemplateConfig && {
                                  includeOptions
                                })}
                                onChange={() => {
                                  form.setFieldValue(
                                    ['config', 'autoGenerateBySubCollectionId'],
                                    null
                                  );
                                }}
                              />
                            </Form.Item>
                          )}
                          {collectionType ===
                            AUTO_GENERATED_TYPES?.COLLECTION &&
                            collectionId && (
                              <Form.Item
                                label="Select SubCollection"
                                name={[
                                  'config',
                                  'autoGenerateBySubCollectionId'
                                ]}
                              >
                                <Select
                                  placeholder="Select sub collection"
                                  query={GET_COLLECTION_ITEMS}
                                  variablesSelector={() => ({
                                    where: {
                                      id: collectionId
                                    }
                                  })}
                                  dataSelector={(data) =>
                                    data?.collectionItemsAdmin?.collectionItems?.map(
                                      (item) => ({
                                        label: item?.itemData?.title,
                                        value: item?.itemData?.id
                                      })
                                    ) ?? []
                                  }
                                  includeOptions={[
                                    { label: 'All', value: 'all' }
                                  ]}
                                  keys={{
                                    data: 'collectionItemsAdmin',
                                    records: 'collectionItems',
                                    count: 'count'
                                  }}
                                />
                              </Form.Item>
                            )}
                          <Form.Item
                            label="Auto Generated Sort By"
                            name={['config', 'autoGenerateSortBy']}
                          >
                            <AntdSelect
                              options={AUTO_GENERATED_SORT_BY_OPTIONS}
                              placeholder="Select sort by"
                            />
                          </Form.Item>
                          {isMultiVideo && (
                            <Form.Item
                              label="No. of Auto generated Videos"
                              name={['config', 'autoGenerateLimit']}
                              rules={[
                                formValidatorRules?.number,
                                formValidatorRules?.maxNumberAllowed(
                                  configData?.MAX_AUTO_GENERATE_LIMIT?.value ||
                                    20
                                )
                              ]}
                            >
                              <Input placeholder="Enter number" />
                            </Form.Item>
                          )}
                        </>
                      )}
                    </>
                  )}
                  {!autoGenerate && (
                    <Form.Item
                      label={isMultiVideo ? 'Select Videos' : 'Select Video'}
                      name={['config', 'videos']}
                      extra={
                        isMultiVideo ? 'Select items in order you want' : ''
                      }
                    >
                      <SelectVideos multiple={isMultiVideo} />
                    </Form.Item>
                  )}
                </div>
              </>
            )}
          </Space>
        )}

        <Permissions />
        <div className="d-flex button-section">
          <Space>
            <Form.Item>
              <Button
                type="text"
                htmlType="submit"
                className="text-btn mr-8"
                size="middle"
                disabled={loading}
                loading={loading}
              >
                Save
              </Button>
            </Form.Item>

            <Form.Item>
              <Button
                disabled={loading}
                onClick={onCancel}
                type="text"
                className="text-btn2"
              >
                Cancel
              </Button>
            </Form.Item>
          </Space>
        </div>
      </Space>
    </Form>
  );
};

export default VideoForm;
