import { gql } from '@apollo/client';

export const GET_USERS = gql`
  query users($filter: UsersFilter, $sort: UsersSort) {
    users(filter: $filter, sort: $sort) {
      count
      users {
        id
        email
        firstName
        lastName
        profileImage
        permissions
        isActive
        createdAt
        username
        effectivePlan
        uid
      }
    }
  }
`;

export const GET_USER = gql`
  query user($where: UserUniqueInput!) {
    user(where: $where) {
      firstName
      lastName
      email
      permissions
      roles
      contactNumber
      countryCode
      username
      zipCode
      assignedPlan
      currentPlan
      assignedPlanExpiry
      isActive
      oneTimePlans {
        createdAt
        expiredAt
        isActive
        key
      }
    }
  }
`;
