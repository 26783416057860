import { useMutation } from '@apollo/client';
import { Button, Form, Input, Select, Space, Typography } from 'antd';
import React, { useEffect, useMemo } from 'react';
import {
  AUTO_GENERATED_SORT_BY_OPTIONS,
  AUTO_GENERATED_SORT_BY_TYPES,
  MODULE_TYPES,
  STATIC_DATA_KEYS,
  STATUS_TYPES
} from '../../../../../common/constants';
import { formValidatorRules } from '../../../../../common/utils';
import useStaticData from '../../../../../hooks/useStaticData';
import { FORM_TYPES } from '../../../context/EditPageProvider';
import {
  CREATE_PAGE_MODULE,
  UPDATE_PAGE_MODULE
} from '../../../graphql/Mutations';
import {
  ModuleFields,
  Permissions,
  SelectSources,
  ShowFields,
  Switch
} from './FormInputs';

const getInitialValues = (type) => ({
  title: '',
  description: '',
  status: STATUS_TYPES.PUBLISHED,
  permissions: [],
  settings: {
    title: true,
    description: true,
    sourceImage: true,
    numberOfArticles: true,
    numberOfPodcasts: true,
    numberOfVideos: true
  },
  config: {
    autoGenerate: null,
    autoGenerateLimit: null,
    autoGenerateSortBy: null,
    sources:
      type === MODULE_TYPES.FEATURED_SOURCE
        ? { id: '', url: '', title: '' }
        : []
  }
});

const SETTINGS = [
  {
    name: 'title',
    label: 'Title',
    allowedTypes: [
      MODULE_TYPES.SOURCE_CAROUSEL,
      MODULE_TYPES.SOURCE_LIST,
      MODULE_TYPES.SOURCE_GRID
    ]
  },
  {
    name: 'description',
    label: 'Description',
    allowedTypes: [
      MODULE_TYPES.SOURCE_CAROUSEL,
      MODULE_TYPES.SOURCE_LIST,
      MODULE_TYPES.SOURCE_GRID,
      MODULE_TYPES.FEATURED_SOURCE
    ]
  },
  {
    name: 'sourceImage',
    label: 'Source Image',
    allowedTypes: [MODULE_TYPES.FEATURED_SOURCE]
  },
  {
    name: 'numberOfArticles',
    label: 'Number of Articles',
    allowedTypes: [MODULE_TYPES.FEATURED_SOURCE]
  },
  {
    name: 'numberOfPodcasts',
    label: 'Number of Podcasts',
    allowedTypes: [MODULE_TYPES.FEATURED_SOURCE]
  },
  {
    name: 'numberOfVideos',
    label: 'Number of Videos',
    allowedTypes: [MODULE_TYPES.FEATURED_SOURCE]
  }
];

const MODULE_KEYS = {
  [MODULE_TYPES.SOURCE_CAROUSEL]: 'sourceCarouselModule',
  [MODULE_TYPES.SOURCE_LIST]: 'sourceListModule',
  [MODULE_TYPES.SOURCE_GRID]: 'sourceGridModule',
  [MODULE_TYPES.FEATURED_SOURCE]: 'featuredSourceModule'
};

const CONFIG_TITLE = {
  [MODULE_TYPES.SOURCE_CAROUSEL]: 'Source Carousel Configs',
  [MODULE_TYPES.SOURCE_LIST]: 'Source List Configs',
  [MODULE_TYPES.SOURCE_GRID]: 'Source Grid Configs',
  [MODULE_TYPES.FEATURED_SOURCE]: 'Featured Source Configs'
};

const SourceForm = ({
  form: { type: formType, moduleId, defaultValues, index: order },
  pageId,
  type,
  onCancel,
  onSuccess,
  onSettingsChange
}) => {
  const [form] = Form.useForm();
  const isEdit = formType === FORM_TYPES.EDIT;
  const initialValues = useMemo(() => getInitialValues(type), [type]);

  const { data: configData } = useStaticData(STATIC_DATA_KEYS.CONFIGS);
  const isMultiSource = MODULE_TYPES.FEATURED_SOURCE !== type;

  const configProps = Form.useWatch(['config'], form);
  const settingsProps = Form.useWatch(['settings'], form);
  const { autoGenerate } = configProps ?? initialValues.config;

  const [addEditModule, { loading }] = useMutation(
    isEdit ? UPDATE_PAGE_MODULE : CREATE_PAGE_MODULE
  );

  useEffect(() => {
    if (settingsProps) {
      onSettingsChange?.(settingsProps);
    }
  }, [settingsProps]);

  useEffect(() => {
    if (moduleId && defaultValues && formType === FORM_TYPES.EDIT && form) {
      const idm = defaultValues?.isDefaultModule;

      form.setFieldsValue({
        title: defaultValues?.title ?? '',
        description: defaultValues?.description ?? '',
        status: defaultValues?.status ?? STATUS_TYPES.DRAFT,
        isDefaultModule: idm,
        permissions:
          defaultValues?.permissions?.map((value) => ({
            label: value,
            value
          })) ?? [],
        settings: {
          ...initialValues.settings,
          ...defaultValues?.moduleData?.settings
        },
        config: {
          autoGenerate: defaultValues?.moduleData?.config?.autoGenerate ?? null,
          autoGenerateLimit:
            defaultValues?.moduleData?.config?.autoGenerateLimit || null,
          autoGenerateSortBy:
            defaultValues?.moduleData?.config?.sourceAutoGenerateBy || null,
          sources: isMultiSource
            ? defaultValues?.moduleData?.config?.sources?.map(
                ({ id, name, image }) => ({
                  id,
                  title: name,
                  url: image?.url ?? ''
                })
              ) || []
            : {
                id: defaultValues?.moduleData?.config?.source?.id ?? '',
                title: defaultValues?.moduleData?.config?.source?.title ?? '',
                url: defaultValues?.moduleData?.config?.source?.image?.url ?? ''
              }
        }
      });
    }
  }, [
    form,
    moduleId,
    formType,
    defaultValues,
    form,
    initialValues,
    isMultiSource
  ]);

  const handleSubmit = ({ config, settings, permissions, ...rest }) => {
    const key = MODULE_KEYS[type];
    if (!key) return;

    const {
      sources,
      autoGenerateLimit,
      autoGenerate: autoGenerateSourceConfig,
      ...restConfig
    } = config;

    const payload = {
      ...(!isEdit && {
        type,
        order: order + 1
      }),
      permissions: permissions?.map(({ value }) => value),
      ...rest,
      [key]: {
        settings,
        config: {
          autoGenerate: autoGenerateSourceConfig,
          ...restConfig,
          ...(isMultiSource && {
            sources:
              sources?.map(({ id }, index) => ({
                order: index + 1,
                sourceId: id
              })) ?? [],
            autoGenerateLimit: autoGenerateLimit
              ? Number(autoGenerateLimit)
              : null
          }),
          ...(!isMultiSource && {
            sourceId: sources?.id ?? ''
          })
        }
      }
    };

    addEditModule({
      variables: { data: payload, id: isEdit ? moduleId : pageId }
    }).then(
      ({
        data: {
          addUpdatedPageModule: { pageModule }
        }
      }) => {
        onSuccess(pageModule);
      }
    );
  };

  return (
    <Form
      layout="vertical"
      form={form}
      onFinish={handleSubmit}
      initialValues={initialValues}
    >
      <ModuleFields />
      <Space className="w-full" direction="vertical">
        <ShowFields settings={SETTINGS} type={type} />
        <Typography.Text>{CONFIG_TITLE[type]}</Typography.Text>
        <Space className="w-full" direction="vertical">
          <Form.Item name={['config', 'autoGenerate']} valuePropName="checked">
            <Switch
              reverse
              flexProps={{ justify: 'space-between' }}
              label={
                isMultiSource
                  ? 'Auto Generated Sources'
                  : 'Auto Generated Source'
              }
            />
          </Form.Item>
          {autoGenerate && (
            <>
              <Form.Item
                label="Auto Generated Sort By"
                name={['config', 'autoGenerateSortBy']}
              >
                <Select
                  options={AUTO_GENERATED_SORT_BY_OPTIONS.filter(
                    ({ value }) => value !== AUTO_GENERATED_SORT_BY_TYPES.VIEWS
                  )}
                  placeholder="Select sort by"
                />
              </Form.Item>
              {isMultiSource && (
                <Form.Item
                  label="No. of Auto generated Sources"
                  name={['config', 'autoGenerateLimit']}
                  rules={[
                    formValidatorRules?.number,
                    formValidatorRules?.maxNumberAllowed(
                      configData?.MAX_AUTO_GENERATE_LIMIT?.value || 20
                    )
                  ]}
                >
                  <Input placeholder="Enter number" />
                </Form.Item>
              )}
            </>
          )}
          {!autoGenerate && (
            <Form.Item
              name={['config', 'sources']}
              label={isMultiSource ? 'Select Sources' : 'Select Source'}
              extra={isMultiSource ? 'Select items in order you want' : ''}
            >
              <SelectSources multiple={isMultiSource} />
            </Form.Item>
          )}
        </Space>
        <Permissions />
        <div className="d-flex button-section">
          <Space>
            <Form.Item>
              <Button
                type="text"
                htmlType="submit"
                className="text-btn mr-8"
                size="middle"
                disabled={loading}
                loading={loading}
              >
                Save
              </Button>
            </Form.Item>

            <Form.Item>
              <Button
                disabled={loading}
                onClick={onCancel}
                type="text"
                className="text-btn2"
              >
                Cancel
              </Button>
            </Form.Item>
          </Space>
        </div>
      </Space>
    </Form>
  );
};

export default SourceForm;
