import { gql } from '@apollo/client';

export const GET_NOTIFICATIONS = gql`
  query notificationTemplates(
    $sort: NotificationTemplatesSort
    $filter: NotificationTemplatesFilter
  ) {
    notificationTemplatesAdmin(sort: $sort, filter: $filter) {
      notificationTemplates {
        id
        key
        title
        content
        createdAt
        updatedAt
        type
        isDefault
        languageCode
        variables
      }
      count
    }
  }
`;

export const GET_NOTIFICATION = gql`
  query notificationTemplateAdmin(
    $where: NotificationTemplateWhereUniqueInput!
  ) {
    notificationTemplateAdmin(where: $where) {
      id
      key
      title
      content
      createdAt
      updatedAt
      type
      isDefault
      languageCode
      variables
      externalTemplateKey
    }
  }
`;
