import { gql } from '@apollo/client';

export const COMMON_ASSET_FIELDS = gql`
  fragment CommonAssetFields on Asset {
    id
    url
    blurhash
  }
`;

export const GET_PODCASTS_MODULE = gql`
  query PodcastsAdmin($filter: PodcastsFilter, $sort: PodcastsSort) {
    podcastsAdmin(filter: $filter, sort: $sort) {
      count
      podcasts {
        id
        title
        description
        createdAt
        slug
        imageThumbnail {
          url
          blurhash
        }
      }
    }
  }
`;

export const GET_PODCAST = gql`
  ${COMMON_ASSET_FIELDS}
  query PodcastAdmin($where: PodcastWhereUniqueInput!) {
    podcastAdmin(where: $where) {
      title
      description
      slug
      tags {
        id
        name
      }
      topics {
        id
        name
      }
      status
      contentRating
      metaHeader
      metaFooter
      seo
      speakers {
        id
        firstName
        lastName
        image {
          url
        }
        primaryColor
      }
      image {
        ...CommonAssetFields
      }
      imageThumbnail {
        ...CommonAssetFields
      }
      audio {
        ...CommonAssetFields
        title
      }
      previewAudio {
        ...CommonAssetFields
        title
      }
      video {
        ...CommonAssetFields
        serviceImageThumbnail
        title
      }
      previewVideo {
        ...CommonAssetFields
        serviceImageThumbnail
        title
      }
      sources {
        id
        name
        image {
          id
          url
        }
      }
      descriptionHtml
      permissions
      code
      allowedPlans {
        key
        expiredAt
      }
    }
  }
`;
