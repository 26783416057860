import { useLazyQuery, useMutation } from '@apollo/client';
import { Badge, Select } from 'antd';
import dayjs from 'dayjs';
import { capitalize } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  MODULE_TYPES,
  MODULES,
  PAGE_VERSION_DATE_FORMAT,
  ROUTES,
  STATUS_TYPES
} from '../../common/constants';
import PageHeader from '../../components/PageHeader';
import history from '../../historyData';
import PreviewBar from '../component/Previewbar';
import LayoutRow from '../component/layout/LayoutRow';
import LeftPanel from './component/pageModules/LeftPanel';
import PreviewWrapper from './component/pageModules/Preview';
import RightPanel from './component/pageModules/RightPanel';
import { Switch } from './component/pageModules/moduleForms/FormInputs';
import EditPageProvider, { useEditPage } from './context/EditPageProvider';
import PreviewPageProvider from './context/PreviewPageProvider';
import { UPDATE_PAGE_STATUS } from './graphql/Mutations';
import { PAGE_VERSIONS } from './graphql/Queries';

const PageStatus = ({ status }) => {
  return (
    <div>
      {status === STATUS_TYPES?.DRAFT && (
        <Badge className="status ml-8" count={status} color="#5E5957" />
      )}
      {status === STATUS_TYPES?.PUBLISHED && (
        <Badge className="status ml-8" count={status} color="#52c41a" />
      )}
      {status === STATUS_TYPES?.UNPUBLISHED && (
        <Badge className="status ml-8" count={status} />
      )}
    </div>
  );
};

const EditPage = () => {
  const params = useParams();
  const {
    settings,
    refetchPageDetails,
    isAdding,
    isEditing,
    selectionType
  } = useEditPage();
  const [isPagePublished, setIsPagePublished] = useState(false);
  const [pageVersionOptions, setPageVersionOptions] = useState([]);
  const pageId = params?.id;
  const pagesBreadcrumbs = [
    { label: MODULES?.UI_CONFIGURATIONS },
    { label: MODULES?.PAGES, route: ROUTES?.UI_CONFIG_PAGES },
    params?.id && { label: params?.id },
    { label: 'Edit' }
  ];

  const [pageVersions, { data }] = useLazyQuery(PAGE_VERSIONS, {
    fetchPolicy: 'no-cache',
    onCompleted(res) {
      const pageVersionData = res?.pageVersionsAdmin?.pages?.map((item) => ({
        label: `${dayjs(item?.updatedAt).format(
          PAGE_VERSION_DATE_FORMAT
        )} - ${capitalize(item?.status)}`,
        value: item?.id
      }));
      setPageVersionOptions(pageVersionData);
    }
  });
  const [updatePageStatus, { loading }] = useMutation(UPDATE_PAGE_STATUS);

  const updatedStatus =
    settings?.status === STATUS_TYPES?.DRAFT ? 'Publish Page' : 'Draft Page';

  const hasDraftPage = data?.pageVersionsAdmin?.pages?.some(
    (item) => item?.status === STATUS_TYPES?.DRAFT
  );
  const draftStatus = settings?.status === STATUS_TYPES?.DRAFT;
  const publishedStatus = settings?.status === STATUS_TYPES?.PUBLISHED;

  const shouldShowSwitch =
    (draftStatus || (publishedStatus && !hasDraftPage)) && updatedStatus;

  const onChangePage = () => {
    const newStatus =
      settings?.status === STATUS_TYPES.DRAFT
        ? STATUS_TYPES.PUBLISHED
        : STATUS_TYPES.DRAFT;
    updatePageStatus({
      variables: { id: pageId, status: newStatus }
    })
      .then((res) => {
        const updatedId = res?.data?.updatePageStatus?.page?.id;
        history?.push(`${ROUTES?.UI_CONFIG_PAGES}/${updatedId}/edit`, {
          isUpdate: true
        });
        pageVersions({
          variables: {
            where: {
              id: updatedId
            }
          }
        });
        refetchPageDetails();
        setIsPagePublished(false);
      })
      .catch();
  };

  const onChangePageVersion = (value) => {
    history?.push(`${ROUTES?.UI_CONFIG_PAGES}/${value}/edit`, {
      isUpdate: true
    });
  };

  useEffect(() => {
    pageVersions({
      variables: {
        where: {
          id: params?.id
        }
      }
    });
  }, [params?.id]);

  return (
    <>
      <PageHeader menu={pagesBreadcrumbs}>
        <div className="d-flex align-center">
          {pageVersionOptions?.length > 0 && (
            <Select
              placeholder="Select page versions"
              options={pageVersionOptions}
              onChange={onChangePageVersion}
              disabled={
                isAdding || isEditing || selectionType === MODULE_TYPES.SETTINGS
              }
              className="page-header-select"
            />
          )}
          {updatedStatus && <PageStatus status={settings?.status} />}
          {shouldShowSwitch && (
            <Switch
              onChange={onChangePage}
              label={updatedStatus}
              reverse
              checked={isPagePublished}
              className="ml-8"
              disabled={
                loading ||
                isAdding ||
                isEditing ||
                selectionType === MODULE_TYPES.SETTINGS
              }
            />
          )}
        </div>
      </PageHeader>
      <div className="page-wrapper">
        <div className="page-wrapper-body edit-page">
          <LayoutRow>
            <LeftPanel />
            <RightPanel />
            <PreviewBar>
              <PreviewWrapper />
            </PreviewBar>
          </LayoutRow>
        </div>
      </div>
    </>
  );
};

const wrapper = () => {
  return (
    <PreviewPageProvider>
      <EditPageProvider>
        <EditPage />
      </EditPageProvider>
    </PreviewPageProvider>
  );
};

export default wrapper;
