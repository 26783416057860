import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const UPDATE_NOTIFICATION_TEMPLATE = gql`
  mutation updateNotificationTemplate(
    $where: NotificationTemplateWhereUniqueInput!
    $data: UpdateNotificationTemplateData!
  ) {
    updateNotificationTemplate(where: $where, data: $data) {
      notificationTemplate {
        id
        key
        title
        content
        createdAt
        updatedAt
        type
        isDefault
        languageCode
        variables
      }
      message
    }
  }
`;
