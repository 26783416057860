import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Form, Input, Radio, Space, Table } from 'antd';
import { findKey, map } from 'lodash';
import React, { useEffect, useState } from 'react';
import {
  MAX_LENGTHS,
  MODULES,
  PERMISSIONS,
  ROUTES,
  WORKSPACE_ROLE_LEVEL,
  WORKSPACE_ROLE_PERMISSION
} from '../../common/constants';
import { formValidatorRules } from '../../common/utils';
import LoaderComponent from '../../components/LoaderComponent';
import PageHeader from '../../components/PageHeader';
import useCheckPermission from '../../hooks/useCheckPermission';
import {
  CREATE_WORKSPACE_ROLE,
  UPDATE_WORKSPACE_ROLE
} from './graphql/Mutations';
import { GET_WORKSPACE_ROLE, ROLES_PERMISSIONS } from './graphql/Queries';

const AddEditRole = ({ history, match: { params } }) => {
  const { roleId } = params;
  const isEdit = !!roleId;
  const [form] = Form.useForm();
  const [permissionsLoading, setPermissionsLoading] = useState(true);
  const [defaultSelected, setDefaultSelected] = useState({});
  const [permissions, setPermissions] = useState(null);

  const rolesBreadcrumbs = [
    { label: MODULES?.WORKSPACE_SETTINGS },
    { label: MODULES?.ROLES, route: ROUTES?.WS_ROLES },
    roleId && { label: roleId },
    { label: isEdit ? 'Edit' : 'Add' }
  ].filter(Boolean);

  const [getPermissions, { loading }] = useLazyQuery(ROLES_PERMISSIONS, {
    fetchPolicy: 'network-only',
    onCompleted(res) {
      if (res?.workspaceRolePermissions) {
        setPermissions(res?.workspaceRolePermissions);
        const sortedPermissions = {};
        map(res?.workspaceRolePermissions, (item) => {
          const getSelected = findKey(item?.config, ['isDefault', true]);
          sortedPermissions[item?.key] = getSelected;
        });
        setDefaultSelected(sortedPermissions);
        setPermissionsLoading(false);
      }
    },
    onError() {}
  });

  const [getWorkspaceRole, { loading: fetchingDetails }] = useLazyQuery(
    GET_WORKSPACE_ROLE,
    {
      fetchPolicy: 'network-only',
      onCompleted(res) {
        if (res.workspaceRole) {
          form?.setFieldsValue({ name: res?.workspaceRole?.name });
          if (res?.workspaceRole) {
            const sortedPermissions = {};
            map(res?.workspaceRole?.permissions, (item) => {
              sortedPermissions[item?.key] = item?.level;
            });
            setDefaultSelected({ ...defaultSelected, ...sortedPermissions });
            setPermissionsLoading(false);
          }
        }
      },
      onError() {
        history.push(ROUTES?.WS_ROLES);
      }
    }
  );

  const [addUpdateRole, { loading: updating }] = useMutation(
    isEdit ? UPDATE_WORKSPACE_ROLE : CREATE_WORKSPACE_ROLE,
    { onError() {} }
  );

  const handlePermissionChange = (value, key) => {
    setDefaultSelected({ ...defaultSelected, [key]: value });
  };

  const columns = [
    {
      title: 'Module Name',
      dataIndex: 'name',
      ellipsis: true,
      width: '30%',
      render: (value) => {
        return <b className="module-name">{value}</b>;
      }
    },
    {
      title: 'Access Level',
      dataIndex: 'config',
      width: '70%',
      render: (value, record) => {
        const sortedPermissions = Object.entries(value || PERMISSIONS).sort(
          (a, b) => a[1].order - b[1].order
        );
        const finalPermissions = Object.fromEntries(sortedPermissions);

        return (
          <Radio.Group
            value={defaultSelected[record?.key] || WORKSPACE_ROLE_LEVEL.VIEW}
            onChange={(e) => {
              const { target: { value: selectedValue } = {} } = e;
              handlePermissionChange(selectedValue, record?.key);
            }}
          >
            {map(finalPermissions, (item, key) => {
              if (item?.isActive) {
                return (
                  <Radio value={key} key={key}>
                    {item?.label}
                  </Radio>
                );
              }
            })}
          </Radio.Group>
        );
      }
    }
  ];

  useEffect(() => {
    async function fetchPermissions() {
      await getPermissions();
      if (isEdit) {
        getWorkspaceRole({
          variables: {
            where: {
              id: roleId
            }
          }
        });
      }
    }
    fetchPermissions();
  }, []);

  const handleSubmit = (formValues) => {
    const selectedPermissions = map(defaultSelected, (item, key) => {
      return { key, level: item };
    });

    const payload = {
      ...formValues,
      permissions: selectedPermissions
    };

    addUpdateRole({
      variables: {
        data: payload,
        ...(isEdit && {
          where: {
            id: roleId
          }
        })
      }
    }).then((res) => {
      if (res.data) {
        history.push(ROUTES?.WS_ROLES);
      }
    });
  };

  const handleCancel = () => {
    history.push(ROUTES?.WS_ROLES);
  };

  const isViewOnly = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.ROLE_MANAGEMENT,
      allowedPermissions: [WORKSPACE_ROLE_LEVEL.VIEW]
    }
  ]);

  const isAddEditAllowed = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.ROLE_MANAGEMENT,
      allowedPermissions: [
        WORKSPACE_ROLE_LEVEL.EDIT,
        WORKSPACE_ROLE_LEVEL.DELETE
      ]
    }
  ]);

  return (
    <div>
      <PageHeader menu={rolesBreadcrumbs} />
      <div className="page-wrapper">
        <div className="page-wrapper-body">
          <Form
            className="add-edit-form"
            layout="vertical"
            onFinish={handleSubmit}
            disabled={isViewOnly || fetchingDetails || loading}
            form={form}
          >
            <Form.Item
              label="Name"
              name="name"
              required
              rules={[
                formValidatorRules?.required('Please enter name!'),
                formValidatorRules?.maxLength(MAX_LENGTHS?.NAME)
              ]}
            >
              <Input placeholder="Enter name" />
            </Form.Item>
            <div className="listview">
              <fieldset>
                <legend className="role-legend">Permissions</legend>
                {!fetchingDetails && !loading && !permissionsLoading ? (
                  <Table
                    loading={fetchingDetails || loading || permissionsLoading}
                    rowKey={(obj) => obj?.key}
                    columns={columns}
                    bordered={false}
                    dataSource={permissions}
                    pagination={false}
                    className="roles-table"
                  />
                ) : (
                  <LoaderComponent setHeight={50} />
                )}
              </fieldset>
            </div>
            <div className="d-flex button-section mb-8">
              <Space>
                {isAddEditAllowed && (
                  <Button
                    disabled={loading || updating}
                    loading={updating}
                    type="text"
                    htmlType="submit"
                    className="text-btn mr-8"
                    size="middle"
                  >
                    Save
                  </Button>
                )}

                <Button
                  disabled={loading || updating}
                  type="text"
                  className="text-btn2"
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
              </Space>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default AddEditRole;
