import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Form, Input, Space } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import React, { useEffect, useState } from 'react';
import 'react-phone-input-2/lib/style.css';
import {
  MAX_LENGTHS,
  MODULES,
  ROUTES,
  WORKSPACE_ROLE_LEVEL,
  WORKSPACE_ROLE_PERMISSION
} from '../../common/constants';
import { formValidatorRules } from '../../common/utils';
import PageHeader from '../../components/PageHeader';
import useCheckPermission from '../../hooks/useCheckPermission';
import EditTemplateModal from './EditTemplateModal';
import { UPDATE_NOTIFICATION_TEMPLATE } from './graphql/Mutations';
import { GET_NOTIFICATION } from './graphql/Queries';

const initialValues = {
  title: '',
  type: '',
  languageCode: '',
  externalTemplateKey: '',
  htmlCode: ''
};

const EditTemplate = ({ history, match: { params } }) => {
  const [form] = Form?.useForm();
  const { templateId } = params;

  const noificationBreadcrumbs = [
    {
      label: MODULES.TEMPLATES,
      route: ROUTES.TEMPLATES
    },
    templateId && { label: templateId },
    { label: 'Edit' }
  ]?.filter(Boolean);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const htmlCode = useWatch('htmlCode', form);

  const [getNotification, { data, loading: fetchingDetails }] = useLazyQuery(
    GET_NOTIFICATION,
    {
      fetchPolicy: 'network-only',
      onCompleted({ notificationTemplateAdmin }) {
        form?.setFieldsValue({
          title: notificationTemplateAdmin?.title ?? '',
          type: notificationTemplateAdmin?.type ?? '',
          languageCode: notificationTemplateAdmin?.languageCode ?? '',
          externalTemplateKey:
            notificationTemplateAdmin?.externalTemplateKey || '',
          htmlCode: notificationTemplateAdmin?.content ?? ''
        });
      },
      onError() {}
    }
  );

  const [updateTemplate, { loading }] = useMutation(
    UPDATE_NOTIFICATION_TEMPLATE,
    {
      onCompleted() {
        history?.push(ROUTES.TEMPLATES);
      },
      onError() {}
    }
  );

  useEffect(() => {
    if (templateId) {
      getNotification({
        variables: {
          where: {
            id: templateId
          }
        }
      });
    }
  }, [templateId, form]);

  const handleSubmit = (values) => {
    const payload = {
      where: {
        id: data?.notificationTemplateAdmin?.id
      },
      data: {
        title: values?.title,
        content: values?.htmlCode,
        externalTemplateKey: values?.externalTemplateKey
      }
    };

    updateTemplate({
      variables: payload
    });
  };

  const handleCancel = () => {
    history?.push(ROUTES.TEMPLATES);
  };

  const isViewOnly = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.NOTIFICATION_TEMPLATE_MANAGEMENT,
      allowedPermissions: [WORKSPACE_ROLE_LEVEL.VIEW]
    }
  ]);

  const isAddEditAllowed = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.NOTIFICATION_TEMPLATE_MANAGEMENT,
      allowedPermissions: [
        WORKSPACE_ROLE_LEVEL.EDIT,
        WORKSPACE_ROLE_LEVEL.DELETE
      ]
    }
  ]);

  return (
    <>
      <EditTemplateModal
        openModal={isModalVisible}
        setOpenModal={setIsModalVisible}
        templateData={data?.notificationTemplateAdmin}
        htmlCode={htmlCode}
        form={form}
      />
      <PageHeader menu={noificationBreadcrumbs} />
      <div className="edit-template-wrapper">
        <div className="page-wrapper">
          <div className="page-wrapper-body d-flex">
            <Form
              form={form}
              className="add-edit-form"
              layout="vertical"
              initialValues={initialValues}
              onFinish={handleSubmit}
              disabled={isViewOnly || fetchingDetails}
            >
              <Form.Item
                label="Title"
                name="title"
                rules={[
                  formValidatorRules?.required('Please enter title!'),
                  formValidatorRules?.maxLength(MAX_LENGTHS.TITLE)
                ]}
              >
                <Input placeholder="Enter title" />
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  size="large"
                  onClick={() => setIsModalVisible(true)}
                >
                  Edit Content
                </Button>
              </Form.Item>
              <Form.Item
                label="External Template Key"
                name="externalTemplateKey"
                className="custom-external-input"
                extra="Note: When an external key is provided, the system will utilize a third-party template specified by the key, overriding the default custom template. This allows seamless integration with external template providers for enhanced flexibility."
              >
                <Input placeholder="Enter external template key" />
              </Form.Item>
              <Form.Item label="Type" name="type">
                <Input disabled placeholder="Enter type" />
              </Form.Item>
              <Form.Item label="Language Code" name="languageCode">
                <Input disabled placeholder="Enter language code" />
              </Form.Item>
              <div className="d-flex button-section mb-8">
                <Space>
                  {isAddEditAllowed && (
                    <Button
                      disabled={loading || fetchingDetails}
                      loading={loading}
                      type="text"
                      htmlType="submit"
                      className="text-btn mr-8"
                      size="middle"
                    >
                      Save
                    </Button>
                  )}

                  <Button
                    disabled={loading}
                    type="text"
                    className="text-btn2"
                    onClick={handleCancel}
                  >
                    Cancel
                  </Button>
                </Space>
              </div>
            </Form>
            <div className="preview-container">
              Preview
              <iframe
                title="preview"
                srcDoc={htmlCode ?? data?.notificationTemplateAdmin?.content}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditTemplate;
