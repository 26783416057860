import { Modal } from 'antd';
import React from 'react';

const TemplatePreviewModal = ({ openModal, setOpenModal, templateData }) => {
  const handleCancel = () => {
    setOpenModal(false);
  };

  return (
    <Modal
      open={openModal}
      onCancel={handleCancel}
      centered
      title={`Template: ${templateData?.title}`}
      closable={false}
      className="preview-template-modal"
      footer={null}
    >
      <div className="modal-content">
        Preview
        <iframe title="preview" srcDoc={templateData?.content} />
      </div>
    </Modal>
  );
};

export default TemplatePreviewModal;
