import { useMutation } from '@apollo/client';
import {
  Select as AntdSelect,
  Button,
  Form,
  Input,
  Space,
  Typography
} from 'antd';
import { isArray, isEmpty } from 'lodash';
import React, { useEffect, useMemo } from 'react';
import {
  AUTO_GENERATED_OPTIONS,
  AUTO_GENERATED_SORT_BY_OPTIONS as AUTO_GENERATED_SORT_BY_OPTIONS$,
  AUTO_GENERATED_SORT_BY_TYPES,
  AUTO_GENERATED_TYPES,
  FORM_TYPES as FORM_MODULE_TYPES,
  FORM_TYPE_OPTIONS,
  MODULE_TYPES,
  PAGE_TYPES,
  STATIC_DATA_KEYS,
  STATUS_TYPES,
  TEMPLATE_OPTION,
  getAutoGenerateByDataValue,
  getAutoGeneratedByIdProps
} from '../../../../../common/constants';
import { formValidatorRules } from '../../../../../common/utils';
import useStaticData from '../../../../../hooks/useStaticData';
import { GET_FORMS } from '../../../../form/graphql/Queries';
import { Select } from '../../../../videos/components/FormInputs';
import { FORM_TYPES } from '../../../context/EditPageProvider';
import {
  CREATE_PAGE_MODULE,
  UPDATE_PAGE_MODULE
} from '../../../graphql/Mutations';
import { ModuleFields, Permissions, ShowFields, Switch } from './FormInputs';

const getInitialValues = () => ({
  title: '',
  description: '',
  status: STATUS_TYPES.PUBLISHED,
  permissions: [],
  isDefaultModule: false,
  settings: {
    title: true,
    description: true,
    formTitle: true,
    formDescription: true,
    formPrayer: true,
    formShare: true
  },
  config: {
    type: FORM_MODULE_TYPES.PETITION,
    sortBy: null,
    formLimit: null,
    forms: [],
    autoFillForm: true,
    autoGenerate: false,
    autoGenerateByType: null,
    autoGenerateById: null,
    autoGenerateSortBy: null,
    autoGenerateLimit: null
  }
});

const CONFIG_TITLE = {
  [MODULE_TYPES.FORM_CAROUSEL]: 'Form Carousel Configs',
  [MODULE_TYPES.FORM_DISPLAY]: 'Form Display Configs'
};

const SORT_BY_OPTIONS = [
  { label: 'Recent', value: AUTO_GENERATED_SORT_BY_TYPES.RECENT },
  { label: 'Random', value: AUTO_GENERATED_SORT_BY_TYPES.RANDOM },
  {
    label: 'Form Completion',
    value: AUTO_GENERATED_SORT_BY_TYPES.FORM_COMPLETION
  }
];

const SETTINGS = [
  {
    name: 'title',
    label: 'Title',
    allowedTypes: [MODULE_TYPES.FORM_CAROUSEL]
  },
  {
    name: 'description',
    label: 'Description',
    allowedTypes: [MODULE_TYPES.FORM_CAROUSEL]
  },
  {
    name: 'formTitle',
    label: 'Form Title',
    allowedTypes: [MODULE_TYPES.FORM_DISPLAY]
  },
  {
    name: 'formDescription',
    label: 'Form Description',
    allowedTypes: [MODULE_TYPES.FORM_DISPLAY]
  }
];

const MODULE_KEYS = {
  [MODULE_TYPES.FORM_CAROUSEL]: 'formCarouselModule',
  [MODULE_TYPES.FORM_DISPLAY]: 'formDisplayModule'
};

const { FORM, COLLECTION } = PAGE_TYPES;
const ALLOWED_TEMPLATE_CONFIGS = {
  [MODULE_TYPES.FORM_DISPLAY]: [FORM],
  [MODULE_TYPES.FORM_CAROUSEL]: [COLLECTION]
};

const OPTIONS = AUTO_GENERATED_OPTIONS.filter(
  ({ value }) => value === AUTO_GENERATED_TYPES.COLLECTION
);

const AUTO_GENERATE_SORT_BY_OPTIONS = AUTO_GENERATED_SORT_BY_OPTIONS$.filter(
  ({ value }) => value !== AUTO_GENERATED_SORT_BY_TYPES.VIEWS
);

const FormModule = ({
  form: { type: formType, index: order, moduleId, defaultValues },
  pageId,
  type,
  onCancel,
  onSuccess,
  isDefaultPage,
  pageType,
  onSettingsChange
}) => {
  const [form] = Form.useForm();
  const isEdit = formType === FORM_TYPES.EDIT;
  const { data: configData } = useStaticData(STATIC_DATA_KEYS.CONFIGS);
  const isDefaultModule = Form.useWatch(['isDefaultModule'], form);
  const initialValues = useMemo(() => getInitialValues(), []);
  const formTypeSelected = Form.useWatch(['config', 'type'], form);
  const formSelected = Form.useWatch(['config', 'forms'], form);
  const allowedTemplateConfig =
    isDefaultPage && ALLOWED_TEMPLATE_CONFIGS[type]?.includes(pageType);
  const isMultiForm = type === MODULE_TYPES.FORM_CAROUSEL;
  const configProps = Form.useWatch(['config'], form);
  const settingsProps = Form.useWatch(['settings'], form);
  const { autoGenerateByType, autoGenerate } =
    configProps ?? initialValues.config;

  const autoGenerateByIdProps = getAutoGeneratedByIdProps[autoGenerateByType];

  const [addEditModule, { loading }] = useMutation(
    isEdit ? UPDATE_PAGE_MODULE : CREATE_PAGE_MODULE
  );

  const includeOptions = useMemo(
    () => (autoGenerateByType === pageType ? [TEMPLATE_OPTION] : []),
    [autoGenerateByType, pageType]
  );

  useEffect(() => {
    if (settingsProps) {
      onSettingsChange?.(settingsProps);
    }
  }, [settingsProps]);

  useEffect(() => {
    if (moduleId && defaultValues && formType === FORM_TYPES.EDIT && form) {
      const idm = defaultValues?.isDefaultModule;
      form.setFieldsValue({
        title: defaultValues?.title ?? '',
        description: defaultValues?.description ?? '',
        status: defaultValues?.status ?? STATUS_TYPES.DRAFT,
        isDefaultModule: idm,
        permissions:
          defaultValues?.permissions?.map((value) => ({
            label: value,
            value
          })) ?? [],
        settings: {
          ...initialValues.settings,
          ...defaultValues?.moduleData?.settings
        },
        config: {
          ...defaultValues?.moduleData?.config,
          ...(type === MODULE_TYPES.FORM_CAROUSEL && {
            autoGenerate:
              defaultValues?.moduleData?.config?.autoGenerate ?? false,
            autoGenerateByType:
              defaultValues?.moduleData?.config?.formAutoGenerateByType || null,
            autoGenerateById: idm
              ? TEMPLATE_OPTION
              : getAutoGenerateByDataValue[
                  defaultValues?.moduleData?.config?.formAutoGenerateByType
                ]?.(defaultValues?.moduleData?.config?.autoGenerateByData) ??
                null,
            autoGenerateSortBy:
              defaultValues?.moduleData?.config?.formAutoGenerateSortBy || null,
            autoGenerateLimit:
              defaultValues?.moduleData?.config?.autoGenerateLimit || null
          }),
          type: defaultValues?.moduleData?.config?.type ?? null,
          sortBy: defaultValues?.moduleData?.config?.sortBy || null,
          formLimit: defaultValues?.moduleData?.config?.formLimit || null,
          ...(defaultValues?.moduleData?.config?.type
            ? { forms: [] }
            : {
                ...(!defaultValues?.moduleData?.config?.type && isMultiForm
                  ? {
                      forms:
                        defaultValues?.moduleData?.config?.forms?.map(
                          ({ id, title }) => ({
                            value: id,
                            label: title
                          })
                        ) || []
                    }
                  : {
                      forms: [
                        {
                          value: defaultValues?.moduleData?.config?.form?.id,
                          label: defaultValues?.moduleData?.config?.form?.title
                        }
                      ]
                    })
              })
        }
      });
    }
  }, [form, moduleId, formType, defaultValues, form, initialValues]);

  const handleSubmit = ({
    config,
    settings,
    permissions,
    isDefaultModule: idm,
    ...rest
  }) => {
    const key = MODULE_KEYS[type];
    if (!key) return;

    const isDefault = allowedTemplateConfig && idm;

    const {
      forms,
      sortBy,
      type: formModuleType,
      formLimit,
      autoFillForm,
      autoGenerate: autoGenerate$,
      autoGenerateById,
      autoGenerateLimit,
      ...restConfig
    } = config ?? {};

    const payload = {
      ...(!isEdit && {
        type,
        order: order + 1
      }),
      permissions: permissions?.map(({ value }) => value),
      ...rest,
      [key]: {
        settings,
        config: {
          ...(type === MODULE_TYPES.FORM_CAROUSEL && {
            autoGenerate: autoGenerate$,
            autoGenerateById: autoGenerateById?.value,
            ...(autoGenerateLimit && {
              autoGenerateLimit: Number(autoGenerateLimit)
            }),
            ...restConfig
          }),
          ...(isDefault
            ? {
                formId: '*'
              }
            : {
                ...(!formModuleType &&
                  isMultiForm && {
                    forms:
                      forms?.map(({ value }, i) => ({
                        formId: value,
                        order: i + 1
                      })) ?? []
                  }),
                ...(!formModuleType &&
                  !isMultiForm && {
                    formId: isArray(forms)
                      ? forms?.[0]?.value ?? ''
                      : forms?.value ?? ''
                  })
              }),
          ...(isMultiForm
            ? {
                sortBy,
                type: formModuleType,
                formLimit: formLimit ? Number(formLimit) : null
              }
            : { autoFillForm })
        }
      }
    };

    addEditModule({
      variables: { data: payload, id: isEdit ? moduleId : pageId }
    }).then(
      ({
        data: {
          addUpdatedPageModule: { pageModule }
        }
      }) => {
        onSuccess(pageModule);
      }
    );
  };

  return (
    <div>
      <Form
        layout="vertical"
        form={form}
        onFinish={handleSubmit}
        initialValues={initialValues}
      >
        <ModuleFields />
        <Space className="w-full" direction="vertical">
          <ShowFields settings={SETTINGS} type={type} />
          <Space className="w-full" direction="vertical">
            {allowedTemplateConfig && pageType === FORM && (
              <>
                <Typography.Text>Template Config</Typography.Text>
                <Form.Item
                  className="m-0"
                  name="isDefaultModule"
                  valuePropName="checked"
                >
                  <Switch label="Use Template Data" />
                </Form.Item>
              </>
            )}
            {(!allowedTemplateConfig ||
              (allowedTemplateConfig && !isDefaultModule)) && (
              <>
                <Typography.Text>{CONFIG_TITLE[type]}</Typography.Text>
                <div>
                  {type === MODULE_TYPES.FORM_CAROUSEL && (
                    <>
                      <Form.Item
                        name={['config', 'autoGenerate']}
                        valuePropName="checked"
                      >
                        <Switch
                          label={
                            isMultiForm
                              ? 'Auto Generated Forms'
                              : 'Auto Generated Video'
                          }
                        />
                      </Form.Item>

                      {autoGenerate && (
                        <>
                          <Form.Item
                            label="Auto Generated By Type"
                            name={['config', 'autoGenerateByType']}
                          >
                            <AntdSelect
                              options={OPTIONS}
                              placeholder="Select type"
                              onChange={() => {
                                form.setFieldValue(
                                  ['config', 'autoGenerateById'],
                                  null
                                );
                              }}
                            />
                          </Form.Item>
                          {autoGenerateByType && autoGenerateByIdProps && (
                            <Form.Item
                              label="Auto Generated By"
                              name={['config', 'autoGenerateById']}
                            >
                              <Select
                                placeholder="Select"
                                query={autoGenerateByIdProps?.query}
                                variablesSelector={(filter) => ({
                                  filter
                                })}
                                dataSelector={
                                  autoGenerateByIdProps?.dataSelector
                                }
                                keys={autoGenerateByIdProps?.keys}
                                {...(allowedTemplateConfig && {
                                  includeOptions
                                })}
                              />
                            </Form.Item>
                          )}
                          <Form.Item
                            label="Auto Generated Sort By"
                            name={['config', 'autoGenerateSortBy']}
                          >
                            <AntdSelect
                              options={AUTO_GENERATE_SORT_BY_OPTIONS}
                              placeholder="Select sort by"
                            />
                          </Form.Item>
                          {isMultiForm && (
                            <Form.Item
                              label="No. of Auto generated Videos"
                              name={['config', 'autoGenerateLimit']}
                              rules={[
                                formValidatorRules?.number,
                                formValidatorRules?.maxNumberAllowed(
                                  configData?.MAX_AUTO_GENERATE_LIMIT?.value ||
                                    20
                                )
                              ]}
                            >
                              <Input placeholder="Enter number" />
                            </Form.Item>
                          )}
                        </>
                      )}
                    </>
                  )}

                  {((!autoGenerate && type === MODULE_TYPES.FORM_CAROUSEL) ||
                    type === MODULE_TYPES.FORM_DISPLAY) && (
                    <>
                      {type === MODULE_TYPES.FORM_CAROUSEL && (
                        <>
                          <Form.Item
                            label="Form Type"
                            name={['config', 'type']}
                          >
                            <AntdSelect
                              options={FORM_TYPE_OPTIONS}
                              placeholder="Select type"
                              allowClear
                              disabled={!isEmpty(formSelected)}
                            />
                          </Form.Item>
                          <Form.Item
                            label="Sort By"
                            name={['config', 'sortBy']}
                          >
                            <AntdSelect
                              options={SORT_BY_OPTIONS}
                              placeholder="Select sort by"
                              disabled={!formTypeSelected}
                            />
                          </Form.Item>
                          <Form.Item
                            label="No. of Auto generated Forms"
                            name={['config', 'formLimit']}
                            rules={[
                              formValidatorRules?.number,
                              formValidatorRules?.maxNumberAllowed(
                                configData?.MAX_AUTO_GENERATE_LIMIT?.value || 20
                              )
                            ]}
                          >
                            <Input
                              placeholder="Enter number"
                              disabled={!formTypeSelected}
                            />
                          </Form.Item>
                        </>
                      )}
                      <Form.Item
                        name={['config', 'forms']}
                        label={isMultiForm ? 'Select Forms' : 'Select Form'}
                        extra={
                          isMultiForm ? 'Select items in order you want' : ''
                        }
                      >
                        <Select
                          mode={isMultiForm ? 'multiple' : ''}
                          placeholder="Select forms"
                          query={GET_FORMS}
                          disabled={formTypeSelected}
                          variablesSelector={(filter) => ({
                            filter: {
                              ...filter,
                              status: STATUS_TYPES.PUBLISHED
                            }
                          })}
                          dataSelector={(data) =>
                            data?.formsAdmin?.forms?.map(({ id, title }) => ({
                              label: title,
                              value: id
                            })) ?? 0
                          }
                          allowClear
                          keys={{
                            data: 'formsAdmin',
                            records: 'forms',
                            count: 'count'
                          }}
                        />
                      </Form.Item>
                    </>
                  )}
                </div>
              </>
            )}
            {[MODULE_TYPES.FORM_DISPLAY].includes(type) && (
              <Form.Item
                name={['config', 'autoFillForm']}
                valuePropName="checked"
              >
                <Switch label="Auto Fill Form" />
              </Form.Item>
            )}
          </Space>
          <Permissions />
          <div className="d-flex button-section">
            <Space>
              <Form.Item>
                <Button
                  type="text"
                  htmlType="submit"
                  className="text-btn mr-8"
                  size="middle"
                  disabled={loading}
                  loading={loading}
                >
                  Save
                </Button>
              </Form.Item>

              <Form.Item>
                <Button
                  disabled={loading}
                  onClick={onCancel}
                  type="text"
                  className="text-btn2"
                >
                  Cancel
                </Button>
              </Form.Item>
            </Space>
          </div>
        </Space>
      </Form>
    </div>
  );
};

export default FormModule;
