import {
  DeleteOutlined,
  EditOutlined,
  EyeFilled,
  UploadOutlined
} from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  Select as AntdSelect,
  Button,
  Form,
  Space,
  Tooltip,
  Typography,
  Upload
} from 'antd';
import { find, isArray, isFunction } from 'lodash';
import { ArrowClockwise, Export, FileArrowDown } from 'phosphor-react';
import React, { useContext, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../../AppContext';
import videoPlaceholder from '../../assets/images/imagePlaceholder.png';
import {
  ASSET_CATEGORY,
  CLOUD_FRONT_CACHE_KEYS,
  EXPORT_JOB_TYPES,
  IMPORT_JOBS_STATIC_FILES_URL,
  LIST_TYPES,
  MODULES,
  ROLE_KEYS,
  ROUTES,
  UI_LABEL_TYPE,
  UI_LABEL_TYPES,
  WORKSPACE_ROLE_LEVEL,
  WORKSPACE_ROLE_PERMISSION
} from '../../common/constants';
import { fileUpload, getImageUrl } from '../../common/utils';
import { modalContext } from '../../components/AppComponentContainer';
import FilterBar, { useFilterBar } from '../../components/FilterBar';
import Image from '../../components/Image';
import ModalComponent from '../../components/ModalComponent';
import PageHeader from '../../components/PageHeader';
import PageList from '../../components/PageList';
import ProgressBar from '../../components/ProgressBar';
import useCheckPermission from '../../hooks/useCheckPermission';
import { INVALIDATE_CLOUD_FRONT_CACHE } from '../menus/graphql/Mutations';
import { Select } from '../videos/components/FormInputs';
import {
  CREATE_IMPORT_JOB,
  DELETE_UI_LABEL,
  EXPORT_UI_LABELS
} from './graphql/Mutations';
import {
  GET_LANGUAGES,
  GET_UI_LABELS,
  IMPORT_JOB_SIGNED_URL
} from './graphql/Queries';

const variablesSelector = ({
  limit,
  offset,
  search,
  languageCode,
  labelType
}) => ({
  filter: {
    skip: offset,
    search,
    limit,
    languageCode,
    labelType
  }
});

const initialValues = {
  csv: []
};

const dataSelector = ({ uiLabelsAdmin }) => ({
  data: uiLabelsAdmin?.uiLabels ?? [],
  count: uiLabelsAdmin?.count ?? 0
});

const Action = ({ id, removeItem }) => {
  const {
    state: { currentUser: { roles = [] } = null }
  } = useContext(AppContext);

  const [deleteUILabel] = useMutation(DELETE_UI_LABEL);

  const history = useHistory();
  const isViewOnly = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.UI_CONFIG_MANAGEMENT,
      allowedPermissions: [WORKSPACE_ROLE_LEVEL.VIEW]
    }
  ]);

  const handleClick = () => {
    history.push(`${ROUTES?.UI_CONFIG_LABELS}/${id}/edit`);
  };

  const handleDelete = () => {
    modalContext?.confirm({
      title: 'Are you sure, you want to delete this UI Label?',
      centered: true,
      okText: 'Yes',
      cancelText: 'No',
      okType: 'primary',
      onOk() {
        deleteUILabel({
          variables: { id }
        })
          .then(() => {
            if (removeItem) {
              removeItem(id);
            }
          })
          .catch();
      }
    });
  };

  return (
    <>
      <Tooltip title={`${isViewOnly ? 'View' : 'Edit'} UI Label`}>
        <Button type="text" className="text-btn" onClick={handleClick}>
          {isViewOnly ? <EyeFilled /> : <EditOutlined />}
        </Button>
      </Tooltip>
      {roles?.some((role) =>
        [ROLE_KEYS.ADMIN, ROLE_KEYS.SUPER_ADMIN].includes(role)
      ) && (
        <Tooltip title="Delete UI Label">
          <Button type="text" className="text-btn" onClick={handleDelete}>
            <DeleteOutlined />
          </Button>
        </Tooltip>
      )}
    </>
  );
};

const columns = ({ showPreview, removeItem }) => [
  {
    title: 'Reference Image',
    dataIndex: 'referenceImage',
    ellipsis: true,
    width: 150,
    render: (_, { referenceImage, value, key }) => (
      <Image
        className="group-img-table pointer"
        blurHash={referenceImage?.blurhash}
        src={
          getImageUrl(referenceImage?.url, { height: 50, width: 80 }) ??
          videoPlaceholder
        }
        alt={value}
        onClick={() => {
          if (isFunction(showPreview))
            showPreview({
              title: key,
              url: referenceImage?.url ?? videoPlaceholder,
              type: ASSET_CATEGORY.IMAGE
            });
        }}
      />
    )
  },
  {
    title: 'Key',
    dataIndex: 'key'
  },
  {
    title: 'Value',
    dataIndex: 'value',
    render: (value, { type }) =>
      type === UI_LABEL_TYPES.HTML ? (
        <div
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: value
          }}
        />
      ) : (
        value
      )
  },
  {
    title: 'Description',
    dataIndex: 'description'
  },
  {
    title: 'Type',
    dataIndex: 'type',
    width: 150
  },
  {
    title: 'Action',
    dataIndex: 'id',
    render: (_, { id }) => <Action id={id} removeItem={removeItem} />,
    width: 150
  }
];

const UILabels = () => {
  const [form] = Form.useForm();
  const [language, setLanguage] = useState(null);
  const [labelType, setLabelType] = useState('UI');
  const [importModal, setImportModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const showProgress = progress !== 0 && progress !== 100;
  const history = useHistory();
  const isAddEditAllowed = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.UI_CONFIG_MANAGEMENT,
      allowedPermissions: [
        WORKSPACE_ROLE_LEVEL.EDIT,
        WORKSPACE_ROLE_LEVEL.DELETE
      ]
    }
  ]);

  const isViewOnly = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.UI_CONFIG_MANAGEMENT,
      allowedPermissions: [WORKSPACE_ROLE_LEVEL.VIEW]
    }
  ]);

  const isExportAllowed = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.REPORT_MANAGEMENT,
      allowedPermissions: [
        WORKSPACE_ROLE_LEVEL.EDIT,
        WORKSPACE_ROLE_LEVEL.DELETE
      ]
    }
  ]);
  const filterProps = useFilterBar();

  const filters = useMemo(
    () => ({
      ...filterProps?.filters,
      labelType,
      languageCode: language?.value || 'en'
    }),
    [filterProps?.filters, labelType, language]
  );

  const [invalidateCache, { loading: isInvalidating }] = useMutation(
    INVALIDATE_CLOUD_FRONT_CACHE,
    {
      variables: { key: CLOUD_FRONT_CACHE_KEYS.UI_LABELS }
    }
  );

  const [exportUiLabels, { loading: exportLoading }] = useMutation(
    EXPORT_UI_LABELS
  );

  const [getSignedUrl] = useLazyQuery(IMPORT_JOB_SIGNED_URL, {
    fetchPolicy: 'network-only'
  });
  const [createJob] = useMutation(CREATE_IMPORT_JOB);
  const handleExport = () => {
    exportUiLabels({
      variables: {
        where: {
          languageCode: language?.value,
          type: labelType
        }
      }
    });
  };

  const handleShowModal = () => {
    history.push(`${ROUTES?.UI_CONFIG_LABELS}/add`);
  };

  const handleLanguageChange = (_, newValue) => {
    setLanguage(newValue);
  };

  const handleImportVideos = () => {
    setImportModal(true);
  };

  const handleCancel = () => {
    setImportModal(false);
    form?.resetFields();
  };

  const handleSubmit = async ({ csv }) => {
    setLoading(true);
    const payload = { key: '', uid: '', type: EXPORT_JOB_TYPES?.UI_LABEL };
    try {
      if (csv?.length > 0) {
        const file = csv?.[0]?.originFileObj;
        const { data, error } = await getSignedUrl({
          variables: {
            data: {
              type: EXPORT_JOB_TYPES?.UI_LABEL
            }
          }
        });
        if (error) {
          throw new Error(error);
        }
        if (data?.importJobCsvUploadSignedUrl) {
          await fileUpload(
            data?.importJobCsvUploadSignedUrl?.signedUrl,
            file,
            setProgress
          );
          payload.key = data?.importJobCsvUploadSignedUrl?.key;
          payload.uid = data?.importJobCsvUploadSignedUrl?.uid;
          const { errors } = await createJob({
            variables: {
              data: payload
            }
          });
          if (errors) {
            throw new Error(errors);
          }
          setImportModal(false);
          form?.resetFields();
        }
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };
  const uiLabelsBreadcrumbs = [
    { label: MODULES?.UI_CONFIGURATIONS },
    { label: MODULES?.UI_LABELS }
  ];

  return (
    <>
      <PageHeader
        menu={uiLabelsBreadcrumbs}
        handleShowModal={handleShowModal}
        isAddEditAllowed={isAddEditAllowed}
      />
      <FilterBar
        {...filterProps}
        SearchProps={{ placeholder: 'Search UI Labels' }}
        show={{ listModes: false, sort: false }}
      >
        <Space size="small" wrap>
          <Typography.Text className="sort-by-title" type="secondary">
            Type
          </Typography.Text>
          <AntdSelect
            popupMatchSelectWidth={false}
            value={labelType}
            onChange={setLabelType}
          >
            {UI_LABEL_TYPE?.map(({ label, value }) => (
              <AntdSelect.Option key={value} value={value}>
                {label}
              </AntdSelect.Option>
            ))}
          </AntdSelect>
          <Typography.Text className="sort-by-title" type="secondary">
            Language
          </Typography.Text>
          <Select
            searchable={false}
            popupMatchSelectWidth={false}
            value={language}
            placeholder="Language"
            query={GET_LANGUAGES}
            onChange={handleLanguageChange}
            dataSelector={(data) =>
              data?.languages?.languages?.map(({ id, code, name }) => ({
                id,
                label: name,
                value: code
              })) || []
            }
            keys={{
              data: 'languages',
              records: 'languages',
              count: 'count'
            }}
            queryOptions={{
              fetchPolicy: 'cache-first',
              onCompleted: ({ languages }) => {
                const englishLang = find(
                  languages?.languages,
                  // by default english from backend
                  (item) => ['en', 'EN']?.includes(item?.code)
                );
                setLanguage({
                  label: englishLang?.name,
                  value: englishLang?.code
                });
              }
            }}
          />
          <Tooltip title="Revalidate cache" placement="left">
            <Button
              className="text-btn d-flex align-center justify-center"
              type="text"
              icon={<ArrowClockwise size={24} />}
              onClick={invalidateCache}
              loading={isInvalidating}
            />
          </Tooltip>
          {isExportAllowed && (
            <Tooltip title="Export Ui Labels" placement="left">
              <Button
                className="text-btn d-flex align-center justify-center"
                type="text"
                icon={<Export size={24} weight="thin" />}
                onClick={handleExport}
                loading={exportLoading}
              />
            </Tooltip>
          )}
          <div className="pointer">
            <Button className="mr-8" onClick={handleImportVideos}>
              Import
            </Button>
          </div>
        </Space>
      </FilterBar>
      <PageList
        filters={filters}
        listMode={LIST_TYPES.TABLE}
        query={GET_UI_LABELS}
        variablesSelector={variablesSelector}
        dataSelector={dataSelector}
        TableProps={{
          columns: (props) =>
            columns({ ...props, isAddEditAllowed })?.filter(
              (item) => item !== false
            )
        }}
        keyField="id"
      />
      {importModal && (
        <ModalComponent
          destroyOnClose
          open={importModal}
          footer={null}
          onCancel={handleCancel}
        >
          <Form
            form={form}
            onFinish={handleSubmit}
            name="create-asset"
            layout="vertical"
            className="add-edit-form"
            initialValues={initialValues}
          >
            <Form.Item
              name="csv"
              label="File"
              rules={[{ required: true, message: 'Please select file!' }]}
              getValueFromEvent={(e) => {
                if (isArray(e)) {
                  return e;
                }
                return e?.fileList;
              }}
              valuePropName="fileList"
              extra={
                <a
                  className="d-flex align-center mt-8"
                  download
                  href={`${
                    process.env.REACT_APP_STATIC_DATA_URL
                  }/static/import-jobs/samples/${
                    IMPORT_JOBS_STATIC_FILES_URL[EXPORT_JOB_TYPES?.UI_LABEL]
                  }`}
                >
                  Sample file <FileArrowDown className="ml-8" size={22} />
                </a>
              }
            >
              <Upload
                maxCount={1}
                disabled={isViewOnly || loading}
                accept=".csv"
                beforeUpload={() => false}
              >
                <Space>
                  <Button icon={<UploadOutlined />}>Click to Upload</Button>
                  <span>(.csv)</span>
                </Space>
              </Upload>
            </Form.Item>

            {showProgress && (
              <Form.Item>
                <ProgressBar progress={progress} />
              </Form.Item>
            )}
            <div className="d-flex button-section">
              <Space>
                <Form.Item>
                  <Button
                    loading={loading}
                    type="text"
                    htmlType="submit"
                    className="text-btn mr-8"
                    size="middle"
                  >
                    Save
                  </Button>
                </Form.Item>
                <Form.Item>
                  <Button
                    type="text"
                    className="text-btn2"
                    disabled={loading}
                    onClick={handleCancel}
                  >
                    Cancel
                  </Button>
                </Form.Item>
              </Space>
            </div>
          </Form>
        </ModalComponent>
      )}
    </>
  );
};

export default UILabels;
