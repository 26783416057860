import { Editor } from '@monaco-editor/react';
import { Form, Modal } from 'antd';
import React from 'react';

const EditTemplateModal = ({
  openModal,
  setOpenModal,
  templateData,
  htmlCode,
  form
}) => {
  const handleCancel = () => {
    setOpenModal(false);
  };

  return (
    <Modal
      open={openModal}
      onCancel={handleCancel}
      centered
      title={`${templateData?.title} Template`}
      okText="Save"
      closable={false}
      className="edit-template-modal"
      width="90vw"
      onOk={() => setOpenModal(false)}
    >
      <div className="modal-content">
        <div className="editor-container">
          Enter HTML code here
          <Form form={form}>
            <Form.Item name="htmlCode">
              <Editor
                height="100%"
                language="html"
                theme="vs-dark"
                options={{ minimap: { enabled: false } }}
              />
            </Form.Item>
          </Form>
        </div>
        <div className="preview-container">
          Preview
          <iframe title="preview" srcDoc={htmlCode} />
        </div>
      </div>
    </Modal>
  );
};

export default EditTemplateModal;
